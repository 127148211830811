/**
 * Describes the base of an API response from API's made by Livewall API Framework
 */
export default class APIResponse<T> {
  private _success: boolean = false;
  private _data?: T;
  private _error?: string;
  private _message?: string;

  /**
   * True when the response has a succesfull result
   */
  get isSuccess() {
    return this._success;
  }

  /**
   * Data returned from the call, is only filled when isSuccess is true
   */
  get data() {
    return this._data!;
  }

  /**
   * Error returned from the call, is only filled when isSuccess is false
   */
  get error() {
    return this._error!;
  }

  /**
   * Message accompanying the error returned from the call, is only filled when isSuccess is false
   */
  get message() {
    return this._message!;
  }

  /**
   * Constructs a base API response.
   * @param json Root object of the API response
   * @param dataType Optional param, type of the data object to construct. Should accept a json object and will receive the 'data' property of the root json.
   */
  constructor(json: any, dataType?: new (json: any) => T) {
    this._success = json.success;
    this._error = json.error;
    this._message = json.message;
    if (dataType && this.isSuccess) {
      this._data = new dataType(json);
    }
  }
}
