import moment from 'moment';
import ReservationData from '../../../../models/reservationData';
import Restaurant from '../../../../models/restaurant';
import Table from '../../../../models/table';
import Timeslot from '../../../../models/timeslot';
import api from '../../../../services/api/apiv2';
import TimeslotChecker from '../../../../services/timeslot-checker/timeslot-checker';
import TimeslotGenerator from '../../../../services/timeslotgenerator/timeslotgenerator';
import {DateTime} from 'luxon';

export const calculateAvailableCapacities = (tables: Array<Table>) => {
  if (!tables || tables?.length === 0) {
    return [];
  }
  const maxAmountofChairs = Math.max(
    ...tables.map((table) => table.amountOfChairs),
  );

  return Array.from(Array(maxAmountofChairs).keys()).map((key) => key + 1);
};

export const calculateTimeSlots = async (
  restaurant: Restaurant,
  reservationDate: Date,
  amountOfPersons: number,
  tables: Array<Table>,
) => {
  const reservationDateObj = moment(reservationDate).isSame(new Date(), 'date')
    ? moment(reservationDate)
    : moment(reservationDate).startOf('day');
  const response = await api.reservations.getReservations(
    restaurant.id,
    reservationDateObj,
  );

  if (response) {
    const {reservations} = response;

    let timeslots: Array<Timeslot> = [];
    const openingTimes = restaurant.getOpeningTimesForDate(
      DateTime.fromISO(reservationDate.toISOString()),
    );

    const generated = TimeslotGenerator.generateTimeslots(
      openingTimes,
      15,
      reservationDateObj,
      true,
    );
    // Remove the last entry
    generated.pop();
    timeslots.push(...generated);

    // Remove timeslots which are not for the selected date.
    // Remove this line if we want to show every timeslot defined in the openingtimes instead of per date. if we want to show everytime.
    timeslots = timeslots.filter((timeslot) =>
      timeslot.when.isSame(reservationDate, 'date'),
    );

    // Creates a TimeslotChecker friendly reservationData object
    const reservationData = {
      day: '',
      month: '',
      personAmount: amountOfPersons,
    } as ReservationData;

    timeslots.forEach(
      (timeslot) =>
        (timeslot.available =
          TimeslotChecker.checkTimeslotAvailabilityForTables(
            timeslot,
            reservationData,
            tables,
            reservations,
          )),
    );

    return timeslots.filter((timeSlot) => timeSlot?.available);
  }
};

export const calculateAvailableLocations = (
  tables: Array<Table>,
  requiredCapacity: number,
) => {
  const locations: Array<string> = [];
  tables.forEach((x) => {
    if (
      !locations.includes(x.location) &&
      x.amountOfChairs >= requiredCapacity
    ) {
      locations.push(x.location);
    }
  });
  return locations;
};
