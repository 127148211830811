import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import React, {Component} from 'react';
import './drawer.menu.item.style.scss';

type Props = {
  item: {
    name: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    link: string;
    iconWidth?: number;
    iconHeight?: number;
  };
  onClick: () => void;
};

export default class DrawerMenuItem extends Component<Props> {
  render() {
    const {name, icon, iconWidth, iconHeight} = this.props.item;
    const Icon = icon;
    const width = iconWidth || 26;
    const height = iconHeight || 26;
    return (
      <button className="drawer_menu_item" onClick={this.props.onClick}>
        <div style={{width: 38}}>
          <Icon className="icon" width={width} height={height} fill="#183F57" />
        </div>
        <h1 className="title">{name}</h1>

        <ArrowIcon fill="#BDC3C7" className="drawer_menu_item_arrow" />
      </button>
    );
  }
}
