import React from 'react';
import allergiesList from './allergies.list';
import './allergies.style.scss';
import AllergyItem from './allergy-item.component';

type Props = {
    allergies: Array<string>;
};

export type Allergy = {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>;
    name: string;
    color: string;
    id: string;
};

const Allergies: React.FC<Props> = ({ allergies }) => {
    const items = allergies.map((allergy, index) => {
        const target: Allergy | undefined = allergiesList.find(a => a.id === allergy);

        if (!target) return false;
        return <AllergyItem key={target.id} allergy={target} />;
    }).filter(item => item);

    if (items.length === 0) return <></>;

    return (
        <div className="allergies">
            {items}
        </div>
    );
}

export default Allergies;