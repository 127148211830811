import React from 'react';
import {connect, useSelector} from 'react-redux';
import {AppState} from '../../../redux/reducers/root.reducer';
import Restaurant from '../../../models/restaurant';
import {
  Button,
  Checkbox,
  Input,
  InputGroup,
  Label,
  ModalDialogDescription,
  ModalDialogTitle,
} from '../../../components/ui-base';
import {showNotification} from '../../../redux/actions/notifications.actions';
import AltAction from '../../../components/alt-action/alt.action.component';
import PaymentMethodSelector from '../../../components/payment-method-selector/payment-method-selector';
import DateSelect from './date-select.component';
import moment from 'moment';
import TimeslotSelector from '../../../components/timeslot-selector/timeslot-selector';
import Timeslot from '../../../models/timeslot';
import {NotificationType} from '../../../models/notification';

type ReduxProps = {
  restaurant: Restaurant | undefined;
  account: any;
};

type ReduxActions = {
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => void;
};

type Props = {
  name: string;
  phone: string;
  streetname: string;
  city: string;
  zipcode: string;
  housenumber: string;
  email: string;
  donation: boolean;
  paymentMethod: 'pin' | 'cash' | 'ideal';

  nameInvalid: boolean;
  phoneInvalid: boolean;
  streetInvalid: boolean;
  cityInvalid: boolean;
  zipcodeInvalid: boolean;
  housenumberInvalid: boolean;
  emailInvalid: boolean;

  deliveryTime: moment.Moment;
  timeslots: Array<Timeslot>;

  updateState: (state: {}) => void;
  onDateChangeEvent: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
} & ReduxProps &
  ReduxActions;

const DeliverContentDialog: React.FC<Props> = (props) => {
  const {account, restaurant, updateState, showNotification, zipcode} = props;

  const _prefillAddressFromAccount = () => {
    updateState({
      name: account.firstName + ' ' + account.lastName,
      phone: account.phoneNumber,
      streetname: account.street,
      housenumber: account.houseNumber,
      city: account.city,
      zipcode: account.postalCode,
      email: account.email,
    });
  };

  const _onDeliveryTimeChanged = (selectedTimeslot: Timeslot) => {
    const {deliveryTime} = props;
    deliveryTime.hour(selectedTimeslot.when.hour());
    deliveryTime.minute(selectedTimeslot.when.minute());
    deliveryTime.second(0);
    updateState({deliveryTime: moment(deliveryTime)});
  };

  const restaurantDetail = useSelector(
    (appState: AppState) => appState.restaurants.detailRestaurant,
  );

  const checkPostalRange = () => {
    if (restaurantDetail && restaurantDetail.postalcodeRanges.length > 0) {
      let validZipcode = false;
      const codeToCheck = zipcode.replace(/\D/g, '');
      restaurantDetail.postalcodeRanges.forEach((code) => {
        if (code.includes('-')) {
          if (
            Number(code.split('-')[0]) <= Number(codeToCheck) &&
            Number(code.split('-')[1]) >= Number(codeToCheck)
          ) {
            validZipcode = true;
          }
        } else if (code === codeToCheck) {
          validZipcode = true;
        }
      });
      if (!validZipcode) {
        showNotification(
          'Wij bezorgen helaas niet op deze postcode.',
          NotificationType.ERROR,
        );
        return;
      }
    }
    updateState({showConfirmOrder: true, showDeliverContent: false});
  };

  return (
    <>
      <ModalDialogTitle className="mt-20">Bezorgen</ModalDialogTitle>
      <ModalDialogDescription className="pay_dialog_description">
        Vul onderstaande gegevens in
        {account.loggedIn ? (
          ''
        ) : (
          <span className="register-tip">
            Tip: <a href="/login">Maak een account aan</a> om dit de volgende
            keer over te slaan!
          </span>
        )}
      </ModalDialogDescription>
      {account.loggedIn && (
        <Button
          className="mt-20 mb-20"
          text="Gebruik account gegevens"
          onClick={_prefillAddressFromAccount}
        />
      )}
      <InputGroup>
        <Label>Contactgegevens</Label>
        <Input
          className={`full-width ${props.nameInvalid ? 'invalid' : ''}`}
          placeholder="Naam"
          value={props.name}
          onChange={(event) =>
            updateState({name: event.target.value, nameInvalid: false})
          }
        />
        <Input
          className={`full-width ${props.phoneInvalid ? 'invalid' : ''}`}
          placeholder="Telefoonnummer"
          inputMode="tel"
          value={props.phone}
          onChange={(event) =>
            updateState({phone: event.target.value, phoneInvalid: false})
          }
        />
        <Input
          className={`full-width ${props.streetInvalid ? 'invalid' : ''}`}
          placeholder="Straatnaam"
          value={props.streetname}
          onChange={(event) =>
            updateState({
              streetname: event.target.value,
              streetInvalid: false,
            })
          }
        />
        <Input
          className={`full-width ${props.housenumberInvalid ? 'invalid' : ''}`}
          placeholder="Huisnummer (evt toevoeging)"
          value={props.housenumber}
          onChange={(event) =>
            updateState({
              housenumber: event.target.value,
              housenumberInvalid: false,
            })
          }
        />
        <Input
          className={`full-width ${props.zipcodeInvalid ? 'invalid' : ''}`}
          placeholder="Postcode (eg 1234AB)"
          value={props.zipcode}
          onChange={(event) =>
            updateState({
              zipcode: event.target.value,
              zipcodeInvalid: false,
            })
          }
        />
        <Input
          className={`full-width ${props.cityInvalid ? 'invalid' : ''}`}
          placeholder="Stad"
          value={props.city}
          onChange={(event) =>
            updateState({city: event.target.value, cityInvalid: false})
          }
        />

        <Input
          className={`full-width ${props.emailInvalid ? 'invalid' : ''}`}
          placeholder="E-mail adres"
          inputMode="email"
          value={props.email}
          onChange={(event) =>
            updateState({email: event.target.value, emailInvalid: false})
          }
        />
      </InputGroup>

      <DateSelect
        deliveryTime={props.deliveryTime}
        onChange={props.onDateChangeEvent}
      />
      <TimeslotSelector
        slots={props.timeslots}
        onSelectionChange={_onDeliveryTimeChanged}
      />

      <PaymentMethodSelector
        config={{
          cashEnabled: !!restaurant?.cashEnabled,
          pinEnabled: !!restaurant?.pinEnabled,
          iDealEnabled: !!restaurant?.iDealEnabled,
          iDealCost: restaurant?.iDealCost || '0.00',
        }}
        onSelectionChange={(newPaymentMethod) =>
          updateState({paymentMethod: newPaymentMethod})
        }
        currentValue={props.paymentMethod}
      />

      {restaurant?.enableDonations && (
        <Checkbox
          className="donation-checkbox"
          textComponent={
            <span>
              Ja, ik doneer graag 1 euro voor{' '}
              <a
                href="https://www.metjehart.nl/"
                target="_blank"
                rel="noopener noreferrer">
                stichting Met je Hart
              </a>
            </span>
          }
          checked={props.donation}
          onChecked={(checked) => updateState({donation: checked})}
        />
      )}

      <Button
        text="Bevestig gegevens"
        className="mt-40"
        onClick={checkPostalRange}
      />

      <AltAction
        text="Terug"
        className="mt-20"
        icon="close"
        onClick={() => updateState({showDeliverContent: false})}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    account: state.account,
    restaurant: state.restaurants.detailRestaurant ?? undefined,
  };
};

export default connect(mapStateToProps, {showNotification})(
  DeliverContentDialog,
);
