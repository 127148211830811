import React, {Component} from 'react';
import './order.total.style.scss';
import Formatters from '../../utils/formatters';

export default class OrderTotal extends Component {
  render() {
    const {price} = this.props;
    return (
      <div className="order_total">
        <div className="order_total_row mb-20">
          <div className="order_total_text">Totaal</div>
          <div className="flex-1" />
          <div className="order_total_text price">
            {Formatters.formatCurrency(price)}
          </div>
        </div>
      </div>
    );
  }
}
