import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import {showNotification} from '../../redux/actions/notifications.actions';
import Toolbar from '../../components/toolbar/toolbar.component';
import Content from '../../components/content/content.component';
import {register} from '../../redux/actions/account.actions';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Bg from '../../utils/bg';
import './register.style.scss';
import {
  Checkbox,
  Button,
  Input,
  InputGroup,
  Label,
} from '../../components/ui-base';

class Register extends Component {
  state = {
    email: '',
    password: '',
    passwordVerify: '',
    firstName: '',
    optInChecked: false,
    loading: false,
  };

  render() {
    const {loggedIn} = this.props;
    const {loading} = this.state;

    if (loggedIn) {
      setTimeout(() => this.props.history.replace('/profile'), 0);
      return <></>;
    }

    return (
      <>
        <Toolbar backLink="/login" title="Registreren" />
        <Bg bg="bg-white" />

        <Content toolbar={true}>
          <div alt="header" width="100%" className="login_header" />

          {!loading && this._renderContent()}
          <LoadingIndicator isLoading={loading} />
        </Content>
      </>
    );
  }

  _renderContent = () => {
    const {email, password, passwordVerify, firstName, optInChecked} =
      this.state;

    return (
      <div className="register_content full-width">
        <InputGroup>
          <Label>E-mailadres</Label>
          <Input
            type="email"
            placeholder="Bijv. email@gmail.com"
            value={email}
            onChange={this.onEmailInputChanged}
          />
        </InputGroup>
        <InputGroup>
          <Label>Wachtwoord</Label>
          <Input
            type="password"
            placeholder="**********"
            value={password}
            onChange={this.onPasswordInputChanged}
          />
        </InputGroup>

        <InputGroup>
          <Label>Wachtwoord herhalen</Label>
          <Input
            type="password"
            placeholder="**********"
            value={passwordVerify}
            onChange={this.onPasswordVerifyInputChanged}
          />
        </InputGroup>

        <InputGroup>
          <Label>Voornaam</Label>
          <Input
            type="text"
            placeholder="Bijv. Jan"
            value={firstName}
            onChange={this.onFirstNameInputChanged}
          />
        </InputGroup>
        <InputGroup>
          <Checkbox
            text="Mogen we je email gebruiken voor marketing doeleinden?"
            checked={optInChecked}
            onChecked={(value) => this.setState({optInChecked: value})}
          />
        </InputGroup>

        <Button
          text="Registreren"
          className="mt-20 mb-10"
          onClick={this.register}
        />
      </div>
    );
  };

  onEmailInputChanged = (event) => {
    this.setState({email: event.target.value});
  };

  onPasswordInputChanged = (event) => {
    this.setState({password: event.target.value});
  };

  onPasswordVerifyInputChanged = (event) => {
    this.setState({passwordVerify: event.target.value});
  };

  onFirstNameInputChanged = (event) => {
    this.setState({firstName: event.target.value});
  };

  register = async () => {
    let {email, password, passwordVerify, firstName, optInChecked} = this.state;
    email = email.toLowerCase();

    if (!this.validateEmail(email)) {
      this.props.showNotification('Vul een valide e-mailadres in', 'error');
      return;
    }

    if (password.length === 0) {
      this.props.showNotification('Vul een wachtwoord in', 'error');
      return;
    }

    if (password.length < 6) {
      this.props.showNotification(
        'Het wachtwoord moet minimaal 6 tekens bevatten',
        'error',
      );
      return;
    }

    if (password !== passwordVerify) {
      this.props.showNotification(
        'De ingevulde wachtwoorden komen niet overeen',
        'error',
      );
      return;
    }

    if (firstName.length === 0) {
      this.props.showNotification('Vul een voornaam in', 'error');
      return;
    }

    this.setState({loading: true});

    const response = await this.props.register(
      email,
      password,
      firstName,
      optInChecked,
    );

    this.setState({loading: false});

    if (response === 'email_taken') {
      this.props.showNotification('Het e-mailadres is al bezet', 'error');
      return;
    }

    if (response === 'email_taken') {
      this.props.showNotification('Het e-mailadres is al bezet', 'error');
      return;
    }

    if (response === 'email_not_verified') {
      this.props.showNotification('Het e-mailadres is nog niet geverifieerd', 'error');
      return;
    }

    if (response === 'success') {
      this.props.showNotification(
        'Controleer uw inbox om uw account te verifiëren',
        'success',
      );
      this.props.history.replace('/login');
    } else {
      this.props.showNotification(
        'Onjuist e-mailadres en/of wachtwoord.',
        'error',
      );
    }
  };

  validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.account.loggedIn,
  };
};

export default connect(mapStateToProps, {showNotification, register})(Register);
