import React from 'react'
import './order-item-amount.style.scss';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import {ReactComponent as MinIcon} from '../../assets/icons/min.svg';

type Props = {
    amount: number;
    onChange: (amount : number) => void;
};

const OrderItemAmount: React.FC<Props> = (props: Props) => {
    const {amount, onChange} = props;
    return (
        <div className="order_item_amount_container">
            <div className="order_item_amount" onClick={() => onChange(amount-1)}>
                <MinIcon className="order_item_amount_min"/>
            </div>

            <div className="order_item_amount_value">
                {amount}
            </div>

            <div className="order_item_amount" onClick={() => onChange(amount+1)}>
                <PlusIcon className="order_item_amount_plus"/>
            </div>
        </div>
    );
};

export default OrderItemAmount