import React from 'react';
import {connect} from 'react-redux';
import Restaurant from '../../../models/restaurant';
import {AppState} from '../../../redux/reducers/root.reducer';
import {
  Button,
  Label,
  ModalDialogDescription,
  ModalDialogTitle,
} from '../../../components/ui-base';
import AltAction from '../../../components/alt-action/alt.action.component';
import RestaurantMenuItem from '../../../models/restaurantMenuItem';

type QueueItem = {
  details: {itemId: string};
};

type ReduxProps = {
  restaurant: Restaurant | undefined;
  menuItemsQueue: Array<QueueItem>;
};

type Props = {
  onCancel: () => void;
  menu: RestaurantMenuItem[] | undefined;
  canUpgradeToToGo: () => boolean;
  calculateTimeslots: (state: {
    showTakeoutContent?: boolean;
    showDeliverContent?: boolean;
    takeout: boolean;
    deliver: boolean;
  }) => void;
} & ReduxProps;

const ChoiceContentDialog: React.FC<Props> = (props: Props) => {
  const {
    onCancel,
    restaurant,
    menuItemsQueue,
    canUpgradeToToGo,
    calculateTimeslots,
    menu,
  } = props;

  const restaurantMenu = menu;
  if (restaurantMenu === undefined) {
    return <></>;
  } else {
    const mealTypesInOrder = menuItemsQueue.map((queuedItem) => {
      const item = restaurantMenu.find(
        (menuItem) => (queuedItem as any).id === menuItem.id,
      );
      if (item) {
        return item.mealTypes;
      }
      return ['takeout', 'delivery'];
    });

    const isDeliveryOnly = !mealTypesInOrder.every((typeList: string[]) =>
      typeList.includes('takeout'),
    );
    const isTakeoutOnly = !mealTypesInOrder.every((typeList: string[]) =>
      typeList.includes('delivery'),
    );
    const canUpgradeToGo = canUpgradeToToGo();

    const isMixedOrder = isDeliveryOnly && isTakeoutOnly && !canUpgradeToGo;

    return (
      <>
        <ModalDialogTitle className="mt-20">
          Bezorgen of afhalen
        </ModalDialogTitle>
        <ModalDialogDescription className="pay_dialog_description">
          Wil je je bestelling bezorgd hebben of kom je hem afhalen?
        </ModalDialogDescription>

        {isMixedOrder && (
          <Label>
            Je hebt zowel gerechten die alleen afgehaald als alleen bezorgd
            kunnen worden in je winkelmandje. Neem contact op met het restaurant
            voor deze bestelling.
          </Label>
        )}

        <Button
          disabled={
            restaurant?.reservationTimeslotsDelivery === 0 || isTakeoutOnly
          }
          text="Bezorgen"
          className="mt-40"
          onClick={() =>
            calculateTimeslots({
              showDeliverContent: true,
              takeout: false,
              deliver: true,
            })
          }
        />

        <Button
          disabled={
            (restaurant?.reservationTimeslotsTakeout === 0 || isDeliveryOnly) &&
            !canUpgradeToGo
          }
          text="Afhalen"
          className="mt-20"
          outline={true}
          onClick={() =>
            calculateTimeslots({
              showTakeoutContent: true,
              takeout: true,
              deliver: false,
            })
          }
        />

        <AltAction
          text="Annuleren"
          className="mt-20"
          icon="close"
          onClick={onCancel}
        />
      </>
    );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    menuItemsQueue: state.orders.menuItemsQueue,
    restaurant: state.restaurants.detailRestaurant ?? undefined,
  };
};

export default connect(mapStateToProps, {})(ChoiceContentDialog);
