import {ReactComponent as ActionIcon} from '../../../assets/icons/back.svg';
import React, {ReactElement} from 'react';
import './button.style.scss';
import classNames from 'classnames';

type Props = {
  className?: string;
  centerText?: boolean;
  text: string;
  textOnEnd?: string;
  icon?: ReactElement;
  noIcon?: boolean;
  outline?: boolean;
  flat?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  danger?: boolean;
};

const Button: React.FC<Props> = (props: Props) => {
  const {
    text,
    textOnEnd,
    flat,
    icon,
    noIcon,
    outline,
    onClick,
    disabled = false,
    danger = false,
    centerText,
  } = props;

  const iconFill = {fill: outline ? '#183f57' : '#ffffff'};

  const renderDefaultIcon = (outline?: boolean) => {
    return <ActionIcon className="icon rotate" {...iconFill} />;
  };

  const iconItem = icon ? icon : renderDefaultIcon(outline);

  const buttonClasses = classNames('button', props.className, {
    danger,
    disabled,
    outline,
    flat,
    'centered-text': centerText,
  });

  return (
    <div
      className={buttonClasses}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}>
      <div className="text">{text}</div>
      {textOnEnd && <div className="text align-end">{textOnEnd}</div>}
      {!noIcon && React.cloneElement(iconItem, {...iconFill})}
    </div>
  );
};

export default Button;
