import {SessionDataOrderJSON} from '../../models/json/session-data-order.json';

export const RESTORE_ORDERS = 'RESTORE_ORDERS';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const SUBMITTED_ORDER = 'SUBMITTED_ORDER';
export const UPDATE_TABLE_ORDERS = 'UPDATE_TABLE_ORDERS';
export const SUBMITTED_TABLE_ORDER = 'SUBMITTED_TABLE_ORDER';
export const UPDATE_TABLE_ORDER_HISTORY = 'UPDATE_TABLE_ORDER_HISTORY';

export type RestoreOrdersAction = {
  type: typeof RESTORE_ORDERS;
  payload: Array<any>;
};

export type UpdateOrdersAction = {
  type: typeof UPDATE_ORDERS;
  payload: Array<SessionDataOrderJSON>;
};

export type ClearOrdersAction = {
  type: typeof CLEAR_ORDERS;
  payload: void;
};

export type SubmittedOrderAction = {
  type: typeof SUBMITTED_ORDER;
  payload: void;
};

export type UpdateTableOrdersAction = {
  type: typeof UPDATE_TABLE_ORDERS;
  payload: {
    orderItems: Array<SessionDataOrderJSON>;
  };
};

export type SubmittedTableOrderAction = {
  type: typeof SUBMITTED_TABLE_ORDER;
  payload: void;
};

export type UpdateTableOrderHistoryAction = {
  type: typeof UPDATE_TABLE_ORDER_HISTORY;
  payload: void;
};

export type OrdersActions =
  | UpdateOrdersAction
  | ClearOrdersAction
  | SubmittedOrderAction
  | UpdateTableOrdersAction
  | SubmittedTableOrderAction
  | UpdateTableOrderHistoryAction;
