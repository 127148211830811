import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import Restaurant from '../../../models/restaurant';
import Table from '../../../models/table';
import {NotificationType} from '../../../models/notification';
import ReservationBreadcrumbs from './components/reservation-breadcrumbs/reservation-breadcrumbs';
import ReservationFooter from './components/reservation-footer/reservation-footer';
import ReservationHeader from './components/reservation-header/reservation-header';
import ReservationQuestion from './components/resevation-question/reservation-question';
import api from '../../../services/api/apiv2';
import Formatters from '../../../utils/formatters';
import {loadTables} from '../../../redux/reservations/reservations.thunks';
import {showNotification} from '../../../redux/actions/notifications.actions';
import {
  AppState,
  useAppThunkDispatch,
} from '../../../redux/reducers/root.reducer';
import {
  Step0,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Confirmation,
} from './components/steps';
import {
  CustomerDetails,
  ReservationChangeEventTypes,
  ReservationData,
} from './reservation-interfaces';
import {reservationResponseHelper} from './reservation-response-notification';
import './reservation-dialog.new.scss';
import {
  calculateAvailableLocations,
  calculateTimeSlots,
} from './components/reservation-helpers';
import {getProfile} from '../../../redux/actions/account.actions';

type InputProps = {
  restaurant: Restaurant;
  onCancel: () => void;
};

type ReduxProps = {
  account: any;
  tables: Array<Table>;
};

const ReservationDialog: React.FC<InputProps> = ({restaurant, onCancel}) => {
  const reduxProps: ReduxProps = useSelector((appState: AppState) => ({
    account: appState.account,
    tables: appState.reservations.tables[restaurant.id],
    appState,
  }));
  const [currentStep, setCurrentStep] = useState(0);
  const [nextStepAvailable, setNextStepAvailable] = useState<boolean>(true);
  const [reservationData, setReservationData] = useState<ReservationData>({
    amountOfPersons: 0,
    reservationDate: new Date(),
    reservationTime: undefined,
    preferredLocation: undefined,
    customerDetails: {
      name: reduxProps.account.firstName
        ? `${reduxProps.account.firstName} ${reduxProps.account.lastName}`
        : undefined,
      email: reduxProps.account.email,
      phoneNumber: reduxProps.account.phoneNumber,
    },
  });

  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    dispatch(loadTables(restaurant.id));
    if (reduxProps.account.token) {
      dispatch(getProfile(reduxProps.account.token));
    }
  }, [restaurant.id]);

  const onChangeHandler =
    (type: ReservationChangeEventTypes) =>
    (value: string | number | Date | CustomerDetails) => {
      setReservationData({...reservationData, [type]: value});
      canProceedToNextStep();
    };

  const onPressNext = () => {
    const filledInValues = Object.values(reservationData);
    if (
      (currentStep && !filledInValues[currentStep - 1]) ||
      !nextStepAvailable
    ) {
      return;
    }
    if (currentStep + 1 === 5) {
      needsAdditionalCustomerDataCheck();
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const onPressPrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const canProceedToNextStep = async () => {
    switch (currentStep + 1) {
      case 3:
        const timeSlots = await calculateTimeSlots(
          restaurant,
          reservationData.reservationDate,
          reservationData.amountOfPersons ?? 0,
          reduxProps.tables,
        );
        setNextStepAvailable(!!timeSlots);
        break;
      case 4:
        const locations = await calculateAvailableLocations(
          reduxProps.tables,
          reservationData.amountOfPersons ?? 0,
        );
        setNextStepAvailable(!!locations);
        break;
      default:
        setNextStepAvailable(true);
    }
  };

  const needsAdditionalCustomerDataCheck = () => {
    const customer = reservationData.customerDetails;
    if (customer.email && customer.name && customer.phoneNumber) {
      setCurrentStep(currentStep + 2);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const onSubmit = async () => {
    const response = await api.reservations.makeNew(
      restaurant.id,
      reservationData,
    );
    if (response?.success) {
      showNotification(
        'Reservering succesvol aangemaakt',
        NotificationType.SUCCESS,
      );
      setCurrentStep(currentStep + 1);
      return;
    } else {
      reservationResponseHelper(reservationData, response, dispatch);
    }
  };

  const breadcrumbsTexts = useMemo(() => {
    const array = [];
    if (reservationData.amountOfPersons !== 0) {
      array.push(`${reservationData.amountOfPersons} pers.`);
    }
    if (reservationData.reservationDate) {
      array.push(
        DateTime.fromJSDate(reservationData.reservationDate).toFormat('dd MMM'),
      );
    }

    if (reservationData.reservationTime) {
      array.push(reservationData.reservationTime);
    }

    if (reservationData.preferredLocation) {
      array.push(Formatters.truncate(reservationData.preferredLocation, 6));
    }

    return array;
  }, [reservationData]);

  return (
    <div className="reservation-dialog">
      <ReservationHeader onClose={onCancel} step={currentStep} />
      {/** Reservation Headers */}
      {currentStep > 0 && currentStep < 6 && (
        <>
          <ReservationBreadcrumbs step={currentStep} texts={breadcrumbsTexts} />
          <ReservationQuestion step={currentStep} />
        </>
      )}
      {/** Reservation welcome screen */}
      {currentStep === 0 && <Step0 />}
      {/** Reservation amount of persons selection */}
      {currentStep === 1 && (
        <Step1
          setSelectedAmount={onChangeHandler(
            ReservationChangeEventTypes.AmountOfPersons,
          )}
          amount={reservationData.amountOfPersons}
          tables={reduxProps.tables}
          restaurantEmail={restaurant.email}
          restaurantPhonenumber={restaurant.companyPhoneNumber}
        />
      )}
      {/** Reservation date selection */}
      {currentStep === 2 && (
        <Step2
          reservationDate={reservationData.reservationDate ?? new Date()}
          setReservationDate={onChangeHandler(
            ReservationChangeEventTypes.ReservationDate,
          )}
        />
      )}
      {/** Reservation time selection */}
      {currentStep === 3 && (
        <Step3
          reservationDate={reservationData.reservationDate ?? new Date()}
          reservationTime={reservationData.reservationTime}
          amountOfPersons={reservationData.amountOfPersons}
          setReservationTime={onChangeHandler(
            ReservationChangeEventTypes.ReservationTime,
          )}
          tables={reduxProps.tables}
          restaurant={restaurant}
        />
      )}
      {/** Reservation location selection */}
      {currentStep === 4 && (
        <Step4
          preferredLocation={reservationData.preferredLocation}
          setPreferredLocation={onChangeHandler(
            ReservationChangeEventTypes.PreferredLocation,
          )}
          tables={reduxProps.tables}
          amountOfPersons={reservationData.amountOfPersons ?? 0}
        />
      )}
      {/** Reservation customer details inputfields */}
      {currentStep === 5 && (
        <Step5
          customerDetails={reservationData.customerDetails}
          setCustomerDetails={onChangeHandler(
            ReservationChangeEventTypes.CustomerDetails,
          )}
        />
      )}
      {currentStep === 6 && (
        <Confirmation
          restaurantName={restaurant.title}
          reservationData={reservationData}
        />
      )}
      {currentStep < 6 && (
        <ReservationFooter
          onPress={onPressNext}
          onPressPrevious={onPressPrevious}
          step={currentStep}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default ReservationDialog;
