import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import queryString from "query-string";
import {DateTime} from "luxon";
import ReactVisibilitySensor from "react-visibility-sensor";

import api from "../../services/api";
import SessionType from "../../models/session-type";
import Content from "../../components/content/content.component";
import Bg from "../../utils/bg";
import {ReactComponent as StoreIcon} from "../../assets/icons/store.svg";
import {ReactComponent as ToTopIcon} from "../../assets/icons/arrow-up.svg";
import RestaurantMenuItem from "../../models/restaurantMenuItem";
import {NotificationType} from "../../models/notification";

import RestaurantEatDrinkComponent from "../../components/restaurant-eat-drink/restaurant.eat.drink.component";
import RestaurantDetails from "../../components/restaurant-details/restaurant.details.component";
import RestaurantHeader from "../../components/restaurant-header/restaurant.header.component";
import FloatingButton from "../../components/ui-base/floating-button/floating.button.component";
import AddDishDialog from "../../sections/add-dish-dialog/add.dish.dialog.component";
import MessageDialog from "../../sections/message-dialog/message.dialog.component";
import ReservationButton from "./reservation-button/reservation-button";
import ReservationDialog from "./reservation-dialog/reservation-dialog.new";
import UserGreetingDialog from "./user-greeting/user-greeting.dialog";

import {getRestaurant} from "../../redux/actions/restaurants.actions";
import {getMenu} from "../../redux/actions/restaurant.menu.actions";
import {showNotification} from "../../redux/actions/notifications.actions";
import {addMenuItem} from "../../redux/actions/orders.actions";
import {removeSession, setOrderSession, setTableSession} from "../../redux/session/session.thunks";
import {ServerEvents, useSocketServerConnection} from "../../utils/hooks/use-socket-server-connection.hook";

import RestaurantMenu from "./restaurant-menu";
import {FilterType} from "../../redux/search/filter";
import {AppState} from "../../redux/reducers/root.reducer";

const mealTimeCategories = {
    breakfast: "Ontbijt",
    dinner: "Diner",
    lunch: "Lunch",
    snack: "Snacks",
    takeout: "Afhalen",
};

const Restaurant: React.FC<any> = (props) => {
    const [foodSelected, setFoodSelected] = useState(true);
    const [menuItemPreview, setMenuItemPreview] = useState(null);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [showAddDishId, setShowAddDishId] = useState<number | null>(null);
    const [showAddDishDialog, setShowAddDishDialog] = useState(false);
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [showReservationDialog, setShowReservationDialog] = useState(false);
    const [showToTop, setShowToTop] = useState(false);
    const [messageTitle, setMessageTitle] = useState("");
    const [messageDescription, setMessageDescription] = useState("");
    const [targetMenuItem, setTargetMenuItem] = useState(null);
    const eatDrinkComponent = useRef(null);

    const session = useSelector((state: any) => state.session.session);
    const paymentStatus = useSelector((state: AppState) => state.session?.paymentStatus);
    const restaurant = useSelector((state: any) => state.restaurants.detailRestaurant);
    const menu = useSelector((state: any) => state.restaurantMenu[props.match.params.slug]);
    const dispatch = useDispatch();
    const {connectionState} = useSocketServerConnection();

    useEffect(() => {
        const {match, isPreview, getRestaurant, getMenu, history} = props;

        if (session && session.paymentStatus === "ideal") {
            history.push("/payment-result");
        }

        const slug = match.params.slug;
        getRestaurant(slug);
        if (!menu && slug) {
            getMenu(slug, isPreview);
        }

        if (match.params.menuitemid) {
            setTargetMenuItem(match.params.menuitemid);
        }

        if (window) {
            window.scrollTo(0, 0);
        }
    }, [session]);

    useEffect(() => {
        if (!restaurant) return;
        
        if (menu && targetMenuItem) {
            setTargetMenuItem(null);
            fetchTargetMenuItem(targetMenuItem);
            onMenuItemClicked(parseInt(targetMenuItem, 10), true);
        }

        const parentElement = document.body.parentElement;
        if (parentElement) parentElement.style.overflow = showAddDishDialog || showReservationDialog ? "hidden" : "auto";
    }, [menu, restaurant, showAddDishDialog, showReservationDialog]);

    const fetchTargetMenuItem = async (id: never) => {
        const menuItem = await api.getMenuItemDetails(restaurant.generateSlug(), id, true);
        setMenuItemPreview(menuItem);
    };

    const onEatDrinkChanged = (foodSelected: boolean | ((prevState: boolean) => boolean)) => {
        setFoodSelected(foodSelected);
    };

    const onMenuItemClicked = (id: number, skipCheck: boolean) => {
        const {hasSession} = props;

        if (hasSession && !skipCheck && session.sessionType === SessionType.RESTAURANT) {
            if (paymentStatus === "payment-requested") {
                setShowMessageDialog(true);
                setMessageTitle("Let op");
                setMessageDescription(
                    "Er is een betaalverzoek ingediend. Je kunt geen nieuwe bestellingen meer doen. Wilt je toch opnieuw bestellen? Scan dan opnieuw de QR-code op de tafel."
                );

                return;
            }

            if (paymentStatus === "closed") {
                setShowMessageDialog(true);
                setMessageTitle("Let op");
                setMessageDescription(
                    "U kunt geen bestellingen meer plaatsen. Wilt je toch bestellen. Scan dan opnieuw de QR-code op de tafel."
                );
                return;
            }
        }

        setShowAddDishDialog(true);
        setShowAddDishId(id);
    };

    const addDish = async (menuItem: RestaurantMenuItem, sideDishesSelections: number[], comment: any, amount: any) => {
        let {hasSession} = props;
        const restaurantId = restaurant.id;

        let isNewSession = false;
        let ordersFromHome = !hasSession || session.sessionType === SessionType.FROM_HOME;
        const currentMealTimes = getCurrentMealtimes();
        let sessionToken = session?.token;
        let sameRestaurant = session?.restaurantId === restaurantId;
        const {dishSupportsRemote, restaurantAvailable, canBeOrderedRemotely} = _canMenuItemBeOrderedRemotely(menuItem);

        if (!hasSession && canBeOrderedRemotely) {
            sessionToken = await startSession({
                restaurantId,
                token: restaurant.token,
            });
            isNewSession = true;
            ordersFromHome = true;
        } else if (ordersFromHome && !canBeOrderedRemotely) {
            if (!dishSupportsRemote) {
                dispatch(showNotification("Dit gerecht kan niet besteld worden voor afhalen/bezorgen", NotificationType.ERROR));
            } else if (!restaurantAvailable) {
                dispatch(
                    showNotification(
                        "Afhalen/bezorgen is op dit moment niet beschikbaar voor dit restaurant",
                        NotificationType.ERROR
                    )
                );
            }
            return;
        }

        if (hasSession && !sameRestaurant) {
            if (session.sessionType === SessionType.FROM_HOME) {
                dispatch(
                    showNotification(
                        "U heeft op dit moment gerechten van een ander restaurant in uw order zitten. Verwijder de andere gerechten uit uw order om dit gerecht te bestellen",
                        NotificationType.ERROR
                    )
                );
                return;
            } else if (session.sessionType === SessionType.RESTAURANT) {
                dispatch(
                    showNotification(
                        "U zit op dit moment in een ander restaurant! Scan de QR-code als je wilt wisselen.",
                        NotificationType.ERROR
                    )
                );
                return;
            }
        }

        if (ordersFromHome && !_canMenuItemBeOrderedNow(menuItem)) {
            dispatch(showNotification("Dit gerecht kan niet voor afhalen/bezorgen worden besteld.", NotificationType.ERROR));
            return;
        }

        if (!ordersFromHome) {
            connectionState?.emit(
                ServerEvents.AddOrder,
                {
                    itemId: menuItem.id,
                    sideDishesSelections: sideDishesSelections,
                    comment: comment,
                    amount: amount,
                },
                (response: any) => {
                    if (response.success) {
                        dispatch(showNotification("Gerecht successvol toegevoegd!", NotificationType.SUCCESS));
                    } else {
                        switch (true) {
                            case /Restaurant/i.test(response):
                                dispatch(showNotification("Het restaurant is gesloten.", NotificationType.ERROR));
                                break;
                            case /Kitchen/i.test(response):
                                dispatch(
                                    showNotification(
                                        "De keuken is gesloten. Je kunt alleen nog drinken bestellen.",
                                        NotificationType.ERROR
                                    )
                                );
                                break;
                            case /Mealtype/i.test(response):
                                if (currentMealTimes.length > 0) {
                                    dispatch(
                                        showNotification(
                                            `Dit item kan nu niet besteld worden, je kunt wel ${
                                                mealTimeCategories[currentMealTimes[0] as keyof typeof mealTimeCategories]
                                            } bestellen.`,
                                            NotificationType.ERROR
                                        )
                                    );
                                }
                                break;
                            default:
                                dispatch(showNotification("Er ging iets mis. Probeer het later opnieuw.", NotificationType.ERROR));
                        }

                        if (isNewSession) {
                            dispatch(removeSession());
                        }
                    }
                }
            );
        }

        if (ordersFromHome) {
            const response = (await dispatch(
                addMenuItem(sessionToken, menuItem, sideDishesSelections, comment, amount)
            )) as any;
            if (response.success) {
                dispatch(showNotification("Gerecht successvol toegevoegd!", NotificationType.SUCCESS));
            } else {
                switch (true) {
                    case /Restaurant/i.test(response):
                        dispatch(showNotification("Het restaurant is gesloten.", NotificationType.ERROR));
                        break;
                    case /Kitchen/i.test(response):
                        dispatch(
                            showNotification("De keuken is gesloten. Je kunt alleen nog drinken bestellen.", NotificationType.ERROR)
                        );
                        break;
                    case /Mealtype/i.test(response):
                        if (currentMealTimes.length > 0) {
                            dispatch(
                                showNotification(
                                    `Dit item kan nu niet besteld worden, je kunt wel ${
                                        mealTimeCategories[currentMealTimes[0] as keyof typeof mealTimeCategories]
                                    } bestellen.`,
                                    NotificationType.ERROR
                                )
                            );
                        }
                        break;
                    default:
                        dispatch(showNotification("Er ging iets mis. Probeer het later opnieuw.", NotificationType.ERROR));
                }
            }
        }
        setShowAddDishDialog(false);
    };

    const _isOpened = () => {
        const filteredOpeningTimes = restaurant.openingTimes.filter((time: any) => time.type === "restaurant");

        const daysOfWeek = ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"];
        const currentDate = DateTime.now().setZone('Europe/Amsterdam');
        const currentWeekday = currentDate.weekday - 1; // 0 = Monday, 6 = Sunday
        const currentDay = daysOfWeek[currentWeekday];

        if (restaurant.exceptionOpenOn && restaurant.exceptionOpenOn.includes(currentDate.toFormat("YYYY-MM-DD"))) {
            return true;
        }

        if (
            (restaurant.closedOn && restaurant.closedOn.includes(currentDay)) ||
            (restaurant.exceptionClosedOn && restaurant.exceptionClosedOn.includes(currentDate.toFormat("YYYY-MM-DD")))
        ) {
            return false;
        }

        if (!filteredOpeningTimes) {
            return false;
        }

        const todayTimes = filteredOpeningTimes.find((openingTime: { day: number }) => openingTime.day === currentWeekday);
        if (!todayTimes || !todayTimes.to || !todayTimes.from) {
            return false;
        }

        const currTime = currentDate.toFormat("HH:mm");
        const {to, from} = todayTimes;
        const closingTime = DateTime.fromISO(to).toFormat("HH:mm");
        const openingTime = DateTime.fromISO(from).toFormat("HH:mm");
        return currTime >= openingTime && currTime <= closingTime;
    };

    const _isPreview = () => {
        const data = queryString.parse(props.location.search);
        return data.preview === "lwpv";
    }

    /** Group the menu items in their categories i.e. "Warme maaltijden, broodjes" */
    const _getGroupedMenu = () => {
        const {menuFavorites} = props;
        const filteredMenu = _getFilteredMenu();
        const categories = _getCategories().map((x) => {
            return {category: x, menuItems: []} as any;
        });

        filteredMenu.forEach((x: { category: string | null; id: string | number; highlight: boolean }) => {
            const category = categories.find(
                (y) => y.category.name === x.category || (y.category.name === "Overig" && x.category === null)
            );
            if (category) {
                category.menuItems.push(x);
            }
            if(x.highlight){
                categories.find((y) => y.category.name === "Aanbevolen")?.menuItems.push(x);
            }else if(menuFavorites[x.id]) {
                categories.find((y) => y.category.name === "Favorieten")?.menuItems.push(x);
            }
        });

        return categories
            .filter((category) => category.menuItems.length > 0)
            .sort((a, b) => a.category.orderNum - b.category.orderNum);
    };

    const _canMenuItemBeOrderedNow = (menuItem: { mealTypes: any }) => {
        const {hasSession, restaurantId, session} = props;
        const currentMealTimes = getCurrentMealtimes();
        if (hasSession && session.restaurantId === restaurantId && session.sessionType !== SessionType.FROM_HOME) {
            return currentMealTimes.some(
                (mealTime) => menuItem.mealTypes.length === 0 || menuItem.mealTypes.includes(mealTime)
            );
        }
        return true;
    };

    const _canMenuItemBeOrderedRemotely = (menuItem: { mealTypes: any }) => {
        const restaurantAvailable =
            restaurant.reservationTimeslotsDelivery !== "0" ||
            restaurant.reservationTimeslotsTakeout !== "0" ||
            restaurant.reservationTimeslotsToGo !== "0";
        const dishSupportsRemote =
            menuItem.mealTypes.includes("to-go") ||
            menuItem.mealTypes.includes("takeout") ||
            menuItem.mealTypes.includes("delivery");

        return {
            dishSupportsRemote,
            restaurantAvailable,
            canBeOrderedRemotely: dishSupportsRemote && restaurantAvailable,
        };
    };

    const _getFilteredMenu = () => {
        const {currentMealType} = props;

        if (!menu) return [];
        const {menuFavorites} = props;

        const type = foodSelected ? "eten" : "drinken";
        return menu
            .filter((menuItem: { type: string }) => menuItem.type === type)
            .filter((menuItem: { mealTypes: string | any[] }) =>
                currentMealType ? menuItem.mealTypes.includes(currentMealType.value) : true
            )
            .filter((menuItem: any) => _canMenuItemBeOrderedNow(menuItem))
            .sort((a: { id: string | number }, b: { id: string | number }) => {
                return (menuFavorites[b.id] || false) - (menuFavorites[a.id] || false);
            });
    };

    const _getCategories = () : { type: any; name: any; orderNum: any; image?: any }[] => {
        const {apiCategories, menuFavorites} = props;

        if (!menu) return [];

        const type = foodSelected ? "eten" : "drinken";

        const categories: { type: any; name: any; orderNum: any; image?: any }[] = [];

        const apiCats = apiCategories[props.match.params.slug];

        // add regular categories
        for (const item of menu) {
            if (item.type !== type) {
                continue;
            }
            const alreadyExists = !!categories.find((i) => i.type === "category" && i.name === item.category);
            if (alreadyExists) {
                continue;
            }
            if (!apiCats) {
                continue;
            }
            const matchedCategory = apiCats.find((x: { title: any }) => x.title === item.category);

            if (matchedCategory) {
                categories.push({
                    type: "category",
                    name: item.category,
                    orderNum: matchedCategory.orderNum,
                    image: matchedCategory.image,
                });
            } else if (!categories.find((x) => x.name === "Overig")) {
                categories.push({type: "category", name: "Overig", orderNum: 999});
            }
        }

        if (menu.some((item: { highlight: boolean }) => item.highlight)) {
            categories.push({
                type: "category",
                name: "Aanbevolen",
                orderNum: -1,
            });
        }

        if (menu.some((item: { id: string | number }) => menuFavorites[item.id] !== undefined)) {
            categories.push({type: "category", name: "Favorieten", orderNum: -2});
        }

        return categories.sort((a, b) => a.orderNum - b.orderNum);
    };

    const getCurrentMealtimes = () => {
        const {hasSession, session} = props;

        const isRemoteSession = hasSession && session.sessionType === SessionType.FROM_HOME;
        const currentTime = DateTime.now().setZone('Europe/Amsterdam').toFormat("HH:mm");
        const currentMealTimes: any[] = [];
        restaurant.mealTimes.forEach((mealTime: { from: string; to: string; mealTypes: { slug: any } }) => {
            const from = DateTime.fromISO(mealTime.from).toFormat("HH:mm");
            const to = DateTime.fromISO(mealTime.to).toFormat("HH:mm");
            if (currentTime >= from && currentTime <= to) {
                currentMealTimes.push(mealTime.mealTypes.slug);
            }
        });
        if (isRemoteSession) {
            return currentMealTimes.filter((x) => x !== "breakfast" && x !== "lunch" && x !== "dinner");
        }
        return currentMealTimes;
    };

    const goToProfile = () => {
        const {account} = props;
        if (account.loggedIn) {
            props.history.push("/profile");
        } else {
            props.history.push("/login");
        }
    };

    const startSession = async ({restaurantId, token}: any) => {
        const {account, loggedIn, setOrderSession} = props;
        const userToken = loggedIn ? account.token : null;

        const sessionToken = await setOrderSession(restaurantId, token, userToken);

        if (sessionToken) {
            return sessionToken;
        }
    };

    const _renderDishDialog = () => {
        const cancel = () => setShowAddDishDialog(false);

        let menuItem = menu?.find((item: { id: null }) => item.id === showAddDishId);
        if (!menuItem) {
            if (!menuItemPreview || (menuItemPreview as any).id !== showAddDishId) {
                return;
            }
            menuItem = menuItemPreview;
        }

        let image = null;
        if (menuItem.image && menuItem.image.length > 0) {
            image = menuItem.image;
        } else if (menuItem.imageSquare && menuItem.imageSquare.length > 0) {
            image = menuItem.imageSquare;
        } else {
            image = restaurant.bannerImage;
        }

        return (
            <AddDishDialog
                menuItem={menuItem}
                restaurantId={restaurant.id}
                image={image}
                viewOnly={restaurant.viewOnly}
                isOpen={_isOpened()}
                isPreview={_isPreview()}
                onCancel={cancel}
                onComplete={addDish}
            />
        );
    };

    const {hasSession, menuItemsQueue, occupation, occupationGroup, hasUserBeenGreeted} = props;

    const basketCount = menuItemsQueue.length;

    if (!restaurant) {
        return <></>;
    }

    const shouldShowUserGreeting = !hasUserBeenGreeted && hasSession && session?.sessionType === SessionType.RESTAURANT;
    const restaurantMenu = _getGroupedMenu();
    return (
        <>
            <Helmet>
                <title>{"Amuse | " + restaurant.title}</title>
            </Helmet>

            <Bg bg="bg-white"/>
            <Content className="restaurant-page">
                <ReactVisibilitySensor
                    partialVisibility={true}
                    onChange={(isVisible) => {
                        setShowToTop(!isVisible);
                    }}
                >
                    <div>
                        <RestaurantHeader
                            showDetails={() => setDetailsVisible(true)}
                            goBack={props.history.goBack}
                            goToProfile={goToProfile}
                            goToScan={() => props.history.push("/scanner")}
                            restaurant={restaurant}
                            occupationGroup={occupationGroup}
                            detailsVisible={detailsVisible}
                        />
                        <RestaurantDetails
                            restaurant={restaurant}
                            visible={detailsVisible}
                            occupation={occupation}
                            hideDetails={() => setDetailsVisible(false)}
                            isOpen={_isOpened()}
                        />
                    </div>
                </ReactVisibilitySensor>

                <RestaurantEatDrinkComponent ref={eatDrinkComponent} onChange={onEatDrinkChanged}/>
                {hasSession && (
                    <FloatingButton onClick={() => props.history.push("/orders")} number={basketCount}>
                        <StoreIcon width={28} height={28}/>
                    </FloatingButton>
                )}

                {restaurantMenu &&
                    <RestaurantMenu menu={restaurantMenu as any} onMenuItemClicked={onMenuItemClicked as any}/>}

                {!hasSession && <ReservationButton onClick={() => setShowReservationDialog(true)}/>}

                {shouldShowUserGreeting && (
                    <UserGreetingDialog
                        restaurant={restaurant}
                        onNoPressed={() => {
                        }}
                        onYesPressed={() => {
                            (eatDrinkComponent.current as any)?.selectDrinks();
                        }}
                    />
                )}

                <FloatingButton
                    className={"back-to-top" + (showToTop ? " visible" : "")}
                    onClick={() => window.scroll({left: 0, top: 0, behavior: "smooth"})}
                >
                    <ToTopIcon width={24} height={24}/>
                </FloatingButton>
            </Content>

            {/* Dialogs */}
            {showMessageDialog && (
                <MessageDialog
                    title={messageTitle}
                    description={messageDescription}
                    onComplete={() => setShowMessageDialog(false)}
                />
            )}
            {showReservationDialog && (
                <ReservationDialog restaurant={restaurant} onCancel={() => setShowReservationDialog(false)}/>
            )}
            {showAddDishDialog && _renderDishDialog()}
        </>
    );
};

const mapStateToProps = (
    state: {
        restaurants: {
            restaurants: any[];
            previewRestaurant: any;
            detailRestaurant: any;
        };
        account: { menuFavorites: any };
        restaurantCategories: any;
        search: { activeFilters: any[] };
        session: { hasSession: any; session: any; hasUserBeenGreeted: any };
        restaurantMenu: { [x: string]: any };
        orders: { menuItemsQueue: any };
        occupation: { groups: any[]; ungroupedRestaurants: any[] };
    },
    props: { match: { params: { slug: string | number } }; location: { search: string } }
) => {
    const slug = props.match.params.slug;
    const data = queryString.parse(props.location.search);
    const isPreview = data.preview === "lwpv";

    let foundRestaurant;
    if (slug) {
        foundRestaurant = state.restaurants.restaurants.find(
            (restaurant: { generateSlug: () => any }) => slug === restaurant.generateSlug()
        );
    }

    let restaurant = isPreview ? state.restaurants.previewRestaurant : foundRestaurant;

    if (state.restaurants.detailRestaurant) {
        restaurant = state.restaurants.detailRestaurant;
    }

    const restaurantId = restaurant && restaurant.id;

    return {
        account: state.account,
        apiCategories: state.restaurantCategories,
        currentMealType: state.search.activeFilters.find((x: { type: FilterType }) => x.type === FilterType.Mealtype),
        hasSession: state.session.hasSession,
        isPreview: isPreview,
        menu: state.restaurantMenu[props.match.params.slug],
        menuFavorites: state.account.menuFavorites || {},
        menuItemsQueue: state.orders.menuItemsQueue,
        occupationGroup: state.occupation.groups.find(
            (x: { restaurants: any[] }) => x.restaurants.find((y: { id: any }) => y.id === restaurantId) !== undefined
        ),
        occupation: state.occupation.ungroupedRestaurants.find((x: { id: any }) => x.id === restaurantId),
        restaurant: restaurant,
        restaurantId: restaurantId,
        restaurants: state.restaurants.restaurants,
        session: state.session.session,
        hasUserBeenGreeted: state.session.hasUserBeenGreeted,
    };
};

export default connect(mapStateToProps, {
    getRestaurant,
    getMenu,
    showNotification,
    addMenuItem,
    removeSession,
    setTableSession,
    setOrderSession,
})(Restaurant);
