import './pay.dialog.style.scss';

import {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {compose} from 'redux';

import AltAction from '../../components/alt-action/alt.action.component';
import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import {ModalDialog} from '../../components/ui-base';
import Session from '../../models/session';
import {AppState} from '../../redux/reducers/root.reducer';
import IdealButton from '../../components/button-ideal/button-ideal';
import CashButton from '../../components/button-cash/button-cash';
import PinButton from '../../components/button-pin/button-pin';
import Restaurant from '../../models/restaurant';
import DialogImageHeader from '../../components/dialog-image-header/dialog-image-header';

type InputProps = {
  onCancel: () => void;
  onPaymentMethodSelected: (paymentMethod: PaymentMethod) => void;
};

type ReduxProps = {
  session: Session;
  restaurants: Array<any>;
  restaurant: Restaurant | undefined;
};

type Props = InputProps &
  ReduxProps &
  RouteComponentProps<{restaurantid: string}>;

type State = {
  loading: boolean;
};

export enum PaymentMethod {
  CASH = 'cash',
  PIN = 'pin',
  IDEAL = 'ideal',
}

class PayDialog extends Component<Props, State> {
  state = {loading: false};

  _showIdeal = () => {
    const {restaurant} = this.props;

    if (restaurant) {
      return restaurant.mollieEnabled && restaurant.mollieValid;
    }
    return false;
  };

  _renderContent = () => {
    const {onCancel, onPaymentMethodSelected, restaurant} = this.props;

    if (!restaurant) {
      return null;
    }
    return (
      <>
        <PinButton
          disabled={!restaurant.pinEnabled}
          onClick={() => onPaymentMethodSelected(PaymentMethod.PIN)}
        />
        <CashButton
          disabled={!restaurant.cashEnabled}
          onClick={() => onPaymentMethodSelected(PaymentMethod.CASH)}
        />
        <IdealButton
          disabled={!this._showIdeal()}
          iDealCost={restaurant.iDealCost}
          onClick={() => onPaymentMethodSelected(PaymentMethod.IDEAL)}
        />

        <AltAction
          text="Annuleren"
          className="mt-20"
          icon="close"
          onClick={onCancel}
        />
      </>
    );
  };

  render() {
    const {loading} = this.state;

    return (
      <ModalDialog>
        <DialogImageHeader />

        <h1 className="pay_dialog_title">Betalen</h1>
        <h2 className="pay_dialog_description">Betaal met pin of cash.</h2>

        {!loading && this._renderContent()}

        <LoadingIndicator isLoading={loading} />
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): ReduxProps => {
  const {session} = state.session;
  return {
    session: state.session.session!,
    restaurants: state.restaurants.restaurants,
    restaurant: state.restaurants.detailRestaurant ?? state.restaurants.restaurants.find( r => r.id === session?.restaurantId),
  };
};

export default compose<React.ComponentClass<InputProps>>(
  withRouter,
  connect(mapStateToProps),
)(PayDialog);
