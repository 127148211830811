import React, {Component} from 'react';
import CategorySelect from '../../components/category-select/category-select.component';
import RestaurantMenuItemView from '../../components/restaurant-menu-item/restaurant.menu.item.component';
import RestaurantMenuItem from '../../models/restaurantMenuItem';
import MenuCategoryHeader from './menu/category-header/menu-category-header.component';

type Props = {
  account?: any;
  addMenuFavorite?: (token: string, menuItemId: string) => void;
  menu: Array<RestaurantMenuItem>;
  menuFavorites?: any;
  removeMenuFavorite?: (token: string, menuItemId: string) => void;
  onMenuItemClicked: (menuItemId: string) => void;
  onMenuItemFavoriteClicked?: (menuItemId: string) => void;
};

type State = {
  categorySelectedIndex: number;
  scrollingDown: boolean;
  scanLineOffset: number;
};

export class RestaurantMenu extends Component<Props, State> {
  categoryRefs: Array<React.RefObject<HTMLDivElement>>;
  private lastScrollTop: number = 0;

  constructor(props: Props) {
    super(props);
    this.categoryRefs = props.menu.map((x) => React.createRef());
    this.state = {
      categorySelectedIndex: 0,
      scrollingDown: true,
      scanLineOffset: 0,
    };
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.menu.length !== this.props.menu.length) {
      this.categoryRefs = this.props.menu.map((x) => React.createRef());
      this.setState({categorySelectedIndex: this.state.categorySelectedIndex});
    }
  };

  handleScroll = () => {
    // First, determine scroll percentage of the menu section.
    const menu = document.getElementById('menu');
    if (!menu) {
      return;
    }

    const scrolledDistance = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.clientHeight;

    const totalScrollableHeight = documentHeight - windowHeight;
    let scrollPosition = (scrolledDistance / totalScrollableHeight) * 100;
    scrollPosition = Math.min(100, Math.max(0, scrollPosition));

    const rect = menu.getBoundingClientRect();

    const scanlinePosition = menu.clientHeight * (scrollPosition / 100);
    this.setState({scanLineOffset: scanlinePosition});

    const sections = document.querySelectorAll('.category-section');
    sections.forEach((section, index) => {
      const boundingRect = section.getBoundingClientRect();
      const topInMenu = boundingRect.top - rect.top;
      const bottomInMenu = boundingRect.bottom - rect.top;
      if (scanlinePosition > topInMenu && scanlinePosition < bottomInMenu) {
        this.setState({categorySelectedIndex: index});
      }
    });
  };

  handleCategoryVisibilityChange = (isVisible: boolean, index: number) => {
    // We double-check the index to make sure the right tab is selected. Otherwise
    // we might skip the selected tab to the next if the category is very small.
    if (isVisible) {
      this.setState({categorySelectedIndex: index});
    }
  };

  onCategoryChanged = (index: number) => {
    this.setState({categorySelectedIndex: index});
    const selected = this.categoryRefs[index].current;

    if (selected && selected instanceof HTMLElement) {
      // window.scrollTo(0, selected.offsetTop - 113);
    }

    if (selected === null) {
      return;
    }

    // New stuff below
    // First, determine scroll percentage of the menu section.
    const menu = document.getElementById('menu');
    if (!menu) {
      return;
    }

    // New target scanline position is top of category section relative to the menu.
    const rect = menu.getBoundingClientRect();
    const boundingRect = selected.getBoundingClientRect();
    const scanlineTargetPosition = boundingRect.top - rect.top + 1;

    const windowHeight = window.innerHeight;
    const documentHeight = document.body.clientHeight;
    const totalScrollableHeight = documentHeight - windowHeight;

    // Calculate scroll distance based upon the known scanline position.
    let scrollPosition = (scanlineTargetPosition * 100) / menu.clientHeight;
    let scrolledDistance = (scrollPosition * totalScrollableHeight) / 100;

    window.scrollTo(0, scrolledDistance);
  };

  _renderCategoryHeader = (category: any, index: number) => {
    return <MenuCategoryHeader category={category} />;
  };

  _renderCategorySection = (category: any, categoryIndex: number) => {
    return (
      <div
        key={categoryIndex}
        className="full-width category-section"
        ref={this.categoryRefs[categoryIndex]}>
        {this._renderCategoryHeader(category.category, categoryIndex)}
        {category.menuItems.map((x: any, index: number) =>
          this._renderMenuItem(x, index),
        )}
      </div>
    );
  };

  _renderMenuItem = (menuItem: any, index: number) => {
    const allergies = menuItem.allergies ?? [];

    let image = null;
    if (menuItem.imageSquare && menuItem.imageSquare.length > 0) {
      image = menuItem.imageSquare;
    } else if (menuItem.image && menuItem.image.length > 0) {
      image = menuItem.image;
    }

    return (
      <RestaurantMenuItemView
        key={index}
        allergies={allergies}
        image={image}
        menuItem={menuItem}
        onClick={() => this.props.onMenuItemClicked(menuItem.id)}
      />
    );
  };

  render() {
    const {menu} = this.props;
    const items = menu.map((category, index) =>
      this._renderCategorySection(category, index),
    );
    return (
      <>
        <CategorySelect
          selectedCategoryIndex={this.state.categorySelectedIndex}
          onChange={this.onCategoryChanged}
          categories={menu.map((x) => x.category)}
        />
        <div id="menu">{items}</div>
      </>
    );
  }
}

export default RestaurantMenu;
