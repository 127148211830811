import SessionType from './session-type';
import JoinSessionResponse from '../services/api/responses/joinSessionResponse';

export default class Session {
  sessionType: SessionType;
  token: string;
  restaurantId: number;
  tableNumber?: number;

  static fromJson = (json: any): Session => {
    return new Session(json);
  };

  static fromJoinSessionResponse = (
    joinSessionResponse: JoinSessionResponse,
    sessionType: SessionType,
  ): Session => {
    return new Session({
      ...joinSessionResponse,
      sessionType: sessionType,
    });
  };

  constructor(data: { token: string;  sessionType: SessionType; restaurantId: number; tableNumber?: number; }) {
    this.token = data.token;
    this.restaurantId = data.restaurantId;
    this.tableNumber = data.tableNumber;
    this.sessionType = data.sessionType;
  }
}
