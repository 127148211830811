import api from '../../services/api';
import api2 from '../../services/api/apiv2';
import {menuItemsSearchResultAction} from '../search/search.action';

export function getMenu(slug, isPreview = false) {
  return async function (dispatch) {
    const menu = await api.getMenu(slug, isPreview);

    const menuCategories = await api.getMenuCategories(slug);

    if (menu) {
      dispatch({
        type: 'GET_MENU',
        payload: {id: slug, items: menu},
      });
    }
    if (menuCategories) {
      dispatch({
        type: 'GET_MENU_CATEGORIES',
        payload: {id: slug, items: menuCategories},
      });
    }
  };
}

export function searchMenuItems(filters = []) {
  return async function (dispatch) {
    const menu = await api2.menu.search(filters);

    if (menu) {
      dispatch(menuItemsSearchResultAction(menu));
    }
  };
}
