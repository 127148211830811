import moment, {Moment} from 'moment';
import {OpeningTime} from '../services/timeslotgenerator/timeslotgenerator';
import {DateTime} from 'luxon';

export type MealTime = {mealTypes: {slug: string, type: string}; from: string; to: string};
export type MealTimes = MealTime[];
export type OpeningDay = {
  day: number;
  from: string;
  to: string;
};

function sortDays( a: OpeningDay, b: OpeningDay ) {
  if ( a.day < b.day ){
    return -1;
  }
  if ( a.day > b.day ){
    return 1;
  }
  return 0;
}

export default class Restaurant {
  active: boolean;
  bannerImage: string;
  bannerImage2: string;
  bannerImage3: string;
  cashEnabled: boolean;
  city: string;
  companyPhoneNumber: string;
  crowded: string;
  deliveryCost: string;
  description: string;
  email: string;
  enableDonations: string;
  enableTipping: string;
  groupId: string;
  iDealCost: string;
  iDealEnabled: boolean;
  id: number;
  labels: {[key: string]: string};
  latitude: string;
  longitude: string;
  mealTimes: MealTimes;
  mollieEnabled: boolean;
  mollieValid: boolean;
  numberOfTables: string;
  openingTimes: OpeningDay[];
  pinEnabled: boolean;
  postalCode: string;
  pricing: string;
  profileImage: string;
  reservationEnabled: string;
  reservationCapacityDelivery: number;
  reservationCapacityTakeout: number;
  reservationCapacityToGo: number;
  reservationTimeslotsDelivery: number;
  reservationTimeslotsTakeout: number;
  reservationTimeslotsToGo: number;
  street: string;
  streetNumber: string;
  tableLabels: string;
  terms: string;
  title: string;
  token: string;
  viewOnly: boolean;
  whatsappPhoneNumber: string;
  exceptionClosedOn: string[];
  exceptionOpenOn: string[];
  extraCostMinAmount: number;
  minAmount: number;
  postalcodeRanges: string[];

  constructor(input: any) {
    const json = input.restaurant ?? input;
    this.active = json.active;
    this.bannerImage = json.bannerImage;
    this.bannerImage2 = json.bannerImage2;
    this.bannerImage3 = json.bannerImage3;
    this.cashEnabled = json.cashEnabled;
    this.city = json.city;
    this.companyPhoneNumber = json.companyPhoneNumber;
    this.crowded = json.crowded;
    this.deliveryCost = json.deliveryCost;
    this.description = json.description;
    this.email = json.email;
    this.enableDonations = json.enableDonations;
    this.enableTipping = json.enableTipping;
    this.groupId = json.groupId;
    this.iDealCost = json.idealCost;
    this.id = json.id;
    this.labels = json.labels ?? {};
    this.latitude = json.latitude;
    this.longitude = json.longitude;
    this.mealTimes = json.mealTimes;
    this.mollieEnabled = json.mollieEnabled;
    this.mollieValid = json.mollieValid;
    this.iDealEnabled = this.mollieEnabled && this.mollieValid;
    this.numberOfTables = json.numberOfTables;
    this.openingTimes = json.openingTimes
                          .sort(sortDays);
    this.pinEnabled = json.pinEnabled;
    this.postalCode = json.postalCode;
    this.pricing = json.pricing;
    this.profileImage = json.profileImage;
    this.reservationEnabled = json.reservationEnabled;
    this.reservationTimeslotsDelivery = json.reservationTimeslotsDelivery;
    this.reservationTimeslotsTakeout = json.reservationTimeslotsTakeout;
    this.reservationCapacityDelivery = parseInt(
      json.reservationCapacityDelivery,
    );
    this.reservationCapacityTakeout = parseInt(json.reservationCapacityTakeout);
    this.reservationTimeslotsToGo = json.reservationTimeslotsToGo;
    this.reservationCapacityToGo = parseInt(json.reservationCapacityToGo);
    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.tableLabels = json.tableLabels;
    this.terms = json.terms;
    this.title = json.title;
    this.token = json.token;
    this.viewOnly = json.viewOnly;
    this.whatsappPhoneNumber = json.whatsappPhoneNumber;
    this.exceptionClosedOn = json.exceptionClosedOn
      ? json.exceptionClosedOn.split(',')
      : [];
    this.exceptionOpenOn = json.exceptionOpenOn
      ? json.exceptionOpenOn.split(',')
      : [];
    this.extraCostMinAmount = parseInt(json.extraCostMinAmount);
    this.minAmount = parseInt(json.minAmount);
    this.postalcodeRanges = json.usePostalCodeRanges
      ? json.postalCodeRanges.split(', ')
      : [];
  }

  /**
   * Parsed the passed string into a Moment object
   * @param timeString String to parse, should be formatted HH:mm
   * @param baseMoment Moment to apply this time to, defaults to a new instance. Is not mutated in the process.
   *
   * //TODO: Make it configurable which time to pick, kitchen or restaurant openingtime. Reservations use restaurant, delivery/takeout use kitchen.
   *
   * @returns A Moment object with the passed time string applied.
   */
  private parseTimeString = (
    timeString: string,
    baseMoment: Moment = moment(),
  ): Moment => {
    const splittedTime = timeString.split(':');
    return moment(baseMoment)
      .hour(parseInt(splittedTime[0]))
      .minute(parseInt(splittedTime[1]))
      .second(0);
  };

  getMealTime = (
      slug: 'takeout' | 'delivery' | 'to-go',
    baseMoment: DateTime = DateTime.now().setZone('Europe/Amsterdam'),
  ): OpeningTime[] => {
    const mealTime = this.mealTimes.find((x) => x.mealTypes.slug === slug);
    if (!mealTime) {
      throw new Error(`No mealtime found for slug ${slug}`);
    }
    const mealTimeFrom = DateTime.fromISO(mealTime.from).set({
      day: baseMoment.day,
      month: baseMoment.month,
      year: baseMoment.year,
    });
    const mealTimeTo = DateTime.fromISO(mealTime.to).set({
      day: baseMoment.day,
      month: baseMoment.month,
      year: baseMoment.year,
    });
    return [{from: mealTimeFrom, to: mealTimeTo}];
  };

  /**
   * Generates the openingtimes for the passed date
   * @param queryDate Date to find the openingtimes for
   */
  getOpeningTimesForDate = (queryDate: DateTime): OpeningTime => {
    const weekday = queryDate.weekday - 1; // 0 monday, 6 sunday
    const time = this.openingTimes.find((x) => x.day === weekday)!;

    return {
      from: DateTime.fromISO(time.from),
      to: DateTime.fromISO(time.to),
    };
  };

  generateSlug() {
    let baseParam = this.title
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const city = this.city
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    if (!baseParam.endsWith(city)) {
      baseParam += ` ${city}`;
    }
    const slug = baseParam
      .replace(/[^A-Za-z0-9]+/g, '-')
      .replace(/-$/g, '')
      .toLowerCase();
    return slug;
  }
}
