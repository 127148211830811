import _ from 'lodash';
import {
  CLEAR_ORDERS,
  SUBMITTED_ORDER,
  UPDATE_ORDERS,
  UPDATE_TABLE_ORDERS,
  UPDATE_TABLE_ORDER_HISTORY,
  SUBMITTED_TABLE_ORDER,
  RESTORE_ORDERS
} from '../actiontypes/orders.actiontypes';

const initialState = { menuItemsQueue: [], menuItemsOrdered: [] };

const OrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESTORE_ORDERS:
      const savedState = loadOrders();
      return { ...state, ...savedState };
    case UPDATE_ORDERS:
      return saveState(updateOrders(state, action.payload));
    case SUBMITTED_ORDER:
      return saveState(submittedOrder(state));
    case CLEAR_ORDERS:
      return saveState(clearOrders());
    case UPDATE_TABLE_ORDERS:
      return saveState(updateOrders(state, action.payload));
    case UPDATE_TABLE_ORDER_HISTORY:
      return saveState(updateTableOrderHistory(state, action.payload));
    case SUBMITTED_TABLE_ORDER:
      return saveState(submittedOrder(state));
    default:
      return state;
  }
};

function loadOrders() {
  try {
    const orders = localStorage.getItem('orders');
    if (orders && orders !== 'null') {
      return JSON.parse(orders);
    } 
    return initialState;
  } catch {
    return initialState;
  }
}

function updateOrders(state, payload) {
  let menuItems = payload.orderItems;
  return { ...state, menuItemsQueue: menuItems };
}

function submittedOrder(state) {
  const currentMenuItemsOrdered = state.menuItemsOrdered;
  let menuItemsQueue = [...state.menuItemsQueue];

  // merge
  const orderedMenuItems = currentMenuItemsOrdered.map((item) => {
    const target = menuItemsQueue.find((i) => menuItemEqual(item, i));
    if (target) {
      menuItemsQueue = menuItemsQueue.filter((i) => !menuItemEqual(i, item));

      return {
        ...item,
        amount: item.amount + target.amount,
        orderIds: item?.orderIds ? [...item.orderIds, target.orderId] : [target.orderId]
      };
    }

    return { ...item };
  });

  // add which were not merged
  menuItemsQueue.forEach((item) => {
    orderedMenuItems.push(item);
  });

  return { ...state, menuItemsQueue: [], menuItemsOrdered: orderedMenuItems };
}

function updateTableOrderHistory(state, payload) {
  return { ...state, menuItemsQueue: [], menuItemsOrdered: payload };
}

function saveState(state) {
  localStorage.setItem('orders', JSON.stringify(state));
  return state;
}

function clearOrders() {
  return { ...initialState };
}

function menuItemEqual(a, b) {
  return a.menuItemId === b.menuItemId && a.comment === b.comment && _.isEqual(a.sideDishes, b.sideDishes);
}

function containsOrderId(item, orderId) {
  return item && item.orderId && item.orderId === orderId;
}

export default OrdersReducer;
