import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import placeholder_small from '../../assets/images/placeholder_small.png';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {ReactComponent as BackIcon} from '../../assets/icons/back.svg';
import {ReactComponent as ScanIcon} from '../../assets/icons/scan.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import imageUrl from '../../utils/scalar';
import './restaurant.header.style.scss';
import {Component} from 'react';
import {isMobileOrTablet} from '../../utils/isMobileOrTablet';
import {connect} from 'react-redux';
import Avatar from '../avatar/avatar.component';
import {RestaurantFavoriteButton} from '../restaurant-favorite-button/restaurant-favorite-button.component';
import OccupationGroup from '../../models/occupationGroup';
import Restaurant from '../../models/restaurant';
import {AppState} from '../../redux/reducers/root.reducer';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';

type InputProps = {
  showDetails: () => void;
  detailsVisible: boolean;
  goBack: () => void;
  goToProfile: () => void;
  goToScan: () => void;
  occupationGroup: OccupationGroup;
  restaurant: Restaurant;
};

type ReduxProps = {
  crowdedSuggestion: OccupationGroup;
};

type Props = {} & InputProps & ReduxProps;

type State = {
  headerHeight: number;
};

class RestaurantHeader extends Component<Props, State> {
  autoSlideId: ReturnType<typeof setInterval> | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      headerHeight: isMobileOrTablet()
        ? window.innerWidth / (16 / 9)
        : window.innerHeight * 0.8,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateHeaderSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeaderSize);
  }

  updateHeaderSize = () => {
    this.setState({
      headerHeight: isMobileOrTablet()
        ? window.innerWidth / (16 / 9)
        : window.innerHeight * 0.8,
    });
  };

  render() {
    const {
      crowdedSuggestion,
      showDetails,
      detailsVisible,
      goBack,
      goToProfile,
      goToScan,
      occupationGroup,
      restaurant,
    } = this.props;
    const {headerHeight} = this.state;
    return (
      <div className="restaurant_header">
        <div
          className="restaurant_header_image_holder"
          style={{height: headerHeight}}>
          <div className="restaurant_header_image_holder_content">
            <div style={{width: '100%', height: headerHeight}}>
              <Carousel
                autoPlay
                infiniteLoop
                interval={5000}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}>
                {this.renderSlides(headerHeight)}
              </Carousel>
            </div>

            <div className="restaurant_header_image_overlay" />
          </div>
        </div>
        <button
          className="restaurant_header_button restaurant_back_button"
          onClick={goBack}>
          <BackIcon className="icon" fill="#E7AC5C" />
        </button>
        <div className="restaurant_header_buttons_right">
          <button
            className="restaurant_header_button restaurant_scan_button"
            onClick={goToProfile}>
            <ProfileIcon className="icon" fill="#E7AC5C" />
          </button>
          <button
            className={`restaurant_header_button restaurant_profile_button ${
              isMobileOrTablet() ? '' : 'dont-show-on-desktop'
            }`}
            onClick={goToScan}>
            <ScanIcon className="icon" fill="#E7AC5C" />
          </button>
        </div>

        <Avatar
          url={this._getImage(restaurant.profileImage, placeholder_small)}
          containerStyle={{marginTop: headerHeight - 64 - 148 / 2}}
          innerBorderColor="#183f57"
          outerBorderColor="#FFFFFF"
        />

        <div className="restaurant_header_text_wrapper">
          <h1 className="restaurant_header_name">{restaurant.title}</h1>
          <RestaurantFavoriteButton
            className="restaurant_header_like_icon"
            restaurant={restaurant}
          />
        </div>

        {occupationGroup && occupationGroup.isCrowded && (
          <h2 className="restaurant_header_crowded">
            {`LET OP! Het is hier druk.`}
          </h2>
        )}
        {occupationGroup && occupationGroup.isCrowded && crowdedSuggestion && (
          <h2 className="restaurant_header_crowded">
            {`Ga bijvoorbeeld naar ${crowdedSuggestion.name}`}
          </h2>
        )}
        <h2 className="restaurant_header_description">
          {Object.values(restaurant.labels).join(', ')}
        </h2>
        <div className="restaurant_header_text_wrapper">
          <LocationIcon className="restaurant_header_location_icon" />
          <h3 className="restaurant_header_location">
            {`${restaurant.street} ${restaurant.streetNumber}, ${restaurant.postalCode}, ${restaurant.city}`}
          </h3>
        </div>

        {!detailsVisible && (
          <ArrowIcon
            className="restaurant_header_arrow"
            fill="#E7AC5B"
            onClick={showDetails}
          />
        )}
      </div>
    );
  }

  renderSlides = (height: number) => {
    const imgs = this._getSlideImages();
    return imgs.map((img, index) => {
      return (
        <div
          key={index}
          style={{
            width: '100%',
            height: height,
            backgroundImage: `url("${img.image}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      );
    });
  };

  _getImage = (image: string, fallback: string) => {
    if (image && image.length > 0) {
      return imageUrl(image);
    }

    return fallback;
  };

  _getSlideImages = () => {
    const {bannerImage, bannerImage2, bannerImage3} = this.props.restaurant;

    return [bannerImage, bannerImage2, bannerImage3]
      .filter((item) => item && item.length > 0)
      .map((item) => ({image: imageUrl(item)}));
  };
}

const mapStateToProps = (state: AppState, ownProps: InputProps) => {
  const nonCrowdedGroups = state.occupation.groups.filter(
    (x) => x.city === ownProps.restaurant.city && !x.isCrowded,
  );
  return {
    crowdedSuggestion:
      nonCrowdedGroups[Math.floor(Math.random() * nonCrowdedGroups.length)],
  };
};

export default connect(mapStateToProps, {})(RestaurantHeader);
