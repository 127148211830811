import moment, {Moment} from 'moment';

export default class ValidateReservationResponse {
  restaurantId: number;
  restaurantTitle: string;
  restaurantAddress: string;
  datetime: Moment;
  endDatetime: Moment;
  reservationType: string;
  tableNumber: string;
  numberOfPeople: number;
  isSameHousehold: boolean;
  name: string;

  constructor(dataJson: any) {
    this.restaurantId = dataJson.restaurantId;
    this.restaurantTitle = dataJson.restaurantTitle;
    this.restaurantAddress = dataJson.restaurantAddress;
    this.reservationType = dataJson.reservationType;
    this.datetime = moment(dataJson.datetime);
    this.endDatetime = moment(dataJson.endDatetime);
    this.tableNumber = dataJson.tableNumber;
    this.numberOfPeople = parseInt(dataJson.numberOfPeople);
    this.isSameHousehold = dataJson.isSameHousehold;
    this.name = dataJson.name;
  }
}
