import api from "../../services/api";
import { cancelSession } from "../session/session.thunks";
import SessionType from "../../models/session-type";
import { AppState } from "../reducers/root.reducer";
import {
  CLEAR_ORDERS,
  SUBMITTED_ORDER,
  UPDATE_ORDERS,
  UPDATE_TABLE_ORDER_HISTORY,
  UPDATE_TABLE_ORDERS,
  SUBMITTED_TABLE_ORDER,
  RESTORE_ORDERS,
} from "../actiontypes/orders.actiontypes";

export function restoreOrdersAction() {
  return async function (dispatch: any) {
    dispatch({
      type: RESTORE_ORDERS,
    });
  };
}

export function updateTableOrders(orders: Array<any>) {
  return async function (dispatch: any) {
    dispatch({
      type: UPDATE_TABLE_ORDERS,
      payload: {
        orderItems: orders,
      },
    });
  };
}

export function submittedTableOrder() {
  return async function (dispatch: any) {
    dispatch({
      type: SUBMITTED_TABLE_ORDER,
      payload: {},
    });
  };
}

export function updateTableOrderHistory(orderHistory: Array<any>) {
  return async function (dispatch: any) {
    dispatch({
      type: UPDATE_TABLE_ORDER_HISTORY,
      payload: orderHistory,
    });
  };
}

export function updateOrders(orders: Array<any>) {
  return async function (dispatch: any) {
    dispatch({
      type: UPDATE_ORDERS,
      payload: orders,
    });
  };
}

export function addSubmittedOrder() {
  return async function (dispatch: any) {
    dispatch({
      type: SUBMITTED_ORDER,
      payload: {},
    });
  };
}

export function addMenuItem(
  sessionToken: string,
  menuItem: any,
  sideDishesSelections: any,
  comment: string,
  amount: number
) {
  return async function (dispatch: any) {
    if (sideDishesSelections === "") {
      sideDishesSelections = [];
    }
    // add item
    const response = await api.addOrder(sessionToken, menuItem.id, sideDishesSelections, comment, amount);

    if (response.success) {
      dispatch({
        type: UPDATE_ORDERS,
        payload: {
          orderItems: response.orderItems,
        },
      });
    }

    return Promise.resolve(response);
  };
}

export function changeMenuItemAmount(updatedOrderItems: any[], amount: number) {
  return async function (dispatch: any, getState: () => AppState) {
    dispatch({
      type: UPDATE_ORDERS,
      payload: {
        orderItems: updatedOrderItems,
      },
    });
    if (amount === 0  && updatedOrderItems.length === 0) {
      const state = getState();
      if (
        state.orders.menuItemsQueue.length === 0 &&
        state.session.session !== null &&
        state.session.session.sessionType === SessionType.FROM_HOME
      ) {
        dispatch(clearOrders());
        dispatch(cancelSession());
      }
    }
  };
}

export function submitOrders(sessionToken: string, extraOptions: any) {
  return async function (dispatch: any) {
    try {
      const response = await api.submitOrders(sessionToken, extraOptions);

      if (response.success) {
        dispatch(addSubmittedOrder());
      }
      return Promise.resolve(response);
    } catch (e: any) {
      return Promise.resolve(e.response);
    }
  };
}

export function clearOrders() {
  return async function (dispatch: any) {
    dispatch({
      type: CLEAR_ORDERS,
      payload: {},
    });
  };
}
