import RestaurantOccupation from './restaurantOccupation';

export default class OccupationGroup {
  /** Id for this group */
  id: number;
  /** Name of this group */
  name: string;
  /** City this group is located in */
  city: string;
  /** Total occupation across the group */
  totalOccupationPercentage: number;
  /** Restaurants in this group */
  restaurants: Array<RestaurantOccupation>;
  /** Percentage which indicates when this group is too fulll*/
  threshold: number;

  get isCrowded() {
    return this.totalOccupationPercentage > this.threshold;
  }

  constructor(json: any) {
    this.id = json.id;
    this.name = json.title;
    this.city = json.city;
    this.totalOccupationPercentage = json.totalOccupationPercentage;
    this.restaurants = json.restaurants.map(
      (x: any) => new RestaurantOccupation(x),
    );
    this.threshold = parseInt(json.threshold);
  }
}
