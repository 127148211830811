import React, {useState} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../redux/reducers/root.reducer';
import Formatters from '../../utils/formatters';
import AltAction from '../alt-action/alt.action.component';
import {ActionsOrientation} from '../ui-base/modal-dialog-actions/modal-dialog-actions.component';
import {
    ModalDialog,
    ModalDialogTitle,
    ModalDialogActions,
    Label,
    InputGroup,
    Button,
} from '../ui-base';

import './order-confirmation-dialog.component.scss';
import OrderEntry from './order-entry/order-entry';
import CurrencyInput from '../currency-input/currency-input.component';
import _ from "lodash";

type Props = {
    menu: any;
    orders: any;
    paymentMethod: string;
    restaurant: any;
    onConfirm: (tipValue: number) => void;
    onCancel: () => void;
};

const getItemQueuePrice = (item: any, menu: any) => {
    const originalPrice = item.price;
    if (!menu) return originalPrice;

    if (item.status && item.status !== 'queued') {
        return originalPrice;
    }

    const menuItem = menu.find((i: any) => i.id === item.itemId);

    if (!menuItem) return originalPrice;

    if (menuItem.actionActive) {
        return menuItem.actionPrice;
    }

    return menuItem.price;
};

const getSideDishesPrice = (sideDishes: any) => {
    let sideDishPrice = 0;
    if (!sideDishes || !_.isArray(sideDishes)) return sideDishPrice;

    for (const sideDish of sideDishes) {
        if (sideDish.options && sideDish.options.length > 0) {
            sideDish.options.forEach(
                (option: any) => (sideDishPrice += option.priceModifier),
            );
        }
    }
    return sideDishPrice;
};

const getTotalPrice = (
    order: any,
    paymentMethod: any,
    tipValue: any,
    restaurant: any,
    menu: any,
) => {
    let deliveryCosts = 0;
    let transactionCosts = 0;

    if (paymentMethod === 'ideal') {
        transactionCosts = restaurant.iDealCost ? parseInt(restaurant.iDealCost) : 0;
    }
    const orderItemsPrice = order
        .map(
            (dish: any) => {
                return dish.amount *
                    (getItemQueuePrice(dish, menu) +
                        getSideDishesPrice(dish.sideDishes));
            },
        )
        .reduce((a: any, b: any) => a + b, 0);

    return (
        orderItemsPrice +
        (tipValue ? tipValue : 0) +
        deliveryCosts +
        transactionCosts
    );
};

const OrderConfirmationDialog: React.FC<Props> = (props: Props) => {
    const [tipValue, setTipValue] = useState(0);

    return (
        <ModalDialog className="order-confirmation-dialog">
            <ModalDialogTitle>Bestelling overzicht</ModalDialogTitle>

            <div className="pay_dialog_dishes">
                <h1 className="pay_dialog_secondary_title">Bestelling</h1>

                {props.orders.menuItemsOrdered.map((menuItem: any, index: number) => {

                    return (
                        <OrderEntry
                            key={index}
                            amount={menuItem.amount}
                            title={menuItem.title}
                            price={getItemQueuePrice(menuItem, props.menu)}
                            sideDishes={menuItem.sideDishes}
                        />
                    );
                })}
                {tipValue > 0 && (
                    <>
                        <div className="pay_dialog_menuitem">
                            <div className="pay_dialog_menuitem_title">
                                <span>Fooi</span>
                                <span>{Formatters.formatCurrency(tipValue)}</span>
                            </div>
                        </div>
                    </>
                )}
                {props.paymentMethod === 'ideal' && (
                    <div className="pay_dialog_menuitem">
                        <div className="pay_dialog_menuitem_title">
                            <span>iDeal transactiekosten</span>
                            <span>
                {Formatters.formatCurrency(props.restaurant.iDealCost)}
              </span>
                        </div>
                    </div>
                )}
                <div className="pay_dialog_total">
                    <span>Totaal</span>
                    <span className="pay_dialog_total_price">
            {Formatters.formatCurrency(
                getTotalPrice(
                    props.orders.menuItemsOrdered,
                    props.paymentMethod,
                    tipValue,
                    props.restaurant,
                    props.menu,
                ),
            )}
          </span>
                </div>
            </div>

            {props.restaurant && props.restaurant.enableTipping && (
                <InputGroup>
                    <Label className="full-width">
                        Wil je een fooi geven, vul dan hier een bedrag in.
                    </Label>
                    <CurrencyInput
                        onValueChanged={(valueInCents) => {
                            setTipValue(valueInCents);
                        }}
                    />
                </InputGroup>
            )}

            <ModalDialogActions orientation={ActionsOrientation.Vertical}>
                <Button
                    text="Betaal"
                    className="mt-40"
                    onClick={() => props.onConfirm(tipValue)}
                />
                <AltAction
                    text="Terug"
                    className="mt-20"
                    icon="close"
                    onClick={props.onCancel}
                />
            </ModalDialogActions>
        </ModalDialog>
    );
};

const mapStateToProps = (state: AppState) => {
    const session = state.session;
    let menu;
    let restaurantId = state.restaurants?.detailRestaurant?.id;
    if (session.hasSession) {
        menu = (state.restaurantMenu as any)[`${restaurantId}`];
    }
    return {
        menu: menu,
        orders: state.orders,
        restaurant: state.restaurants.detailRestaurant
    };
};

export default connect(mapStateToProps)(OrderConfirmationDialog);
