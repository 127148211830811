import './search.style.scss';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import Content from '../../components/content/content.component';
import FilterSearchItem from '../../components/filter-search-item/filter.search.item.component';
import SearchToolbar from '../../components/search-toolbar/search.toolbar.component';
import {getLabels} from '../../redux/search/search.thunks';
import Bg from '../../utils/bg';
import {AppState, useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import RestaurantLabel from '../../models/restaurantLabel';
import {useHistory, useLocation} from 'react-router';

type SearchHistoryItem = {
  label: {title: string};
  value: string;
  type: string;
};

type Props = {};

const Search: React.FC<Props> = () => {
  const history = useHistory();
  const highlightedLabels = useSelector((state: AppState) =>
      state.search.labels.filter((x) => x.highlighted),
  );
  const labels = useSelector((state: AppState) => state.search.labels);
  const [searchResults, setSearchResults] = useState<
    Array<{type: string; label?: RestaurantLabel}>
  >([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchHistory, setSearchHistory] = useState<SearchHistoryItem[]>([]);

  const dispatch = useAppThunkDispatch();

  const search = useLocation().search;

  useEffect(() => {
    async function fetchLabels() {
      await dispatch(getLabels());
      const val = new URLSearchParams(search).get('value');
      if (!val) {
        _search('');
      }
    }
    fetchLabels();

    const history = localStorage.getItem('searchHistory');
    if (history) {
      setSearchHistory(JSON.parse(history));
    }
  }, []);

  const clearSearchHistory = () => {
    setSearchHistory([]);
    localStorage.setItem('searchHistory', '[]');
  };

  const _search = useCallback(
    (searchValue: string) => {
      setSearchValue(searchValue);

      const newSearchResults: Array<{type: string; label?: RestaurantLabel}> =
        [];

      labels.forEach((label) => {
        if (
          _match(label.label, searchValue) ||
          _match(label.title, searchValue)
        ) {
          newSearchResults.push({type: 'label', label: label});
        }
      });
      if (searchValue.trim().length > 0) {
        newSearchResults.push({type: 'search'});
      }
      if (searchValue.trim().length > 0) {
        newSearchResults.push({type: 'menu'});
      }

      setSearchResults(newSearchResults);
    },
    [labels],
  );

  useEffect(() => {
    const val = new URLSearchParams(search).get('value');
    if (val) {
      _search(val);
    }
  }, [search, _search]);

  const _match = (haystack: string, needle: string): boolean => {
    return haystack.toLowerCase().includes(needle.toLowerCase());
  };

  const onComplete = useCallback(
    (value: SearchHistoryItem) => {
      if (
        searchHistory.find((item) => item.value === value.value) === undefined
      ) {
        searchHistory.push(value);
      }
      const newSearchHistory = JSON.stringify(searchHistory);
      localStorage.setItem('searchHistory', newSearchHistory);
      history.push('/restaurants');
    },
    [history, searchHistory],
  );

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    _search(event.target.value);
  };

  const searchResultItems = useMemo(() => {
    return searchResults.map((result, index) => {
      if (result.type === 'label') {
        return (
          <FilterSearchItem
            key={index}
            type={result.type}
            value={searchValue}
            label={result.label}
            onComplete={onComplete}
          />
        );
      }
      return (
        <FilterSearchItem
          key={index}
          type={result.type}
          value={searchValue}
          onComplete={onComplete}
        />
      );
    });
  }, [onComplete, searchResults, searchValue]);

  const onDeleteHistoryItem = useCallback(
    (value: string) => {
      searchHistory.splice(
        searchHistory.findIndex((item) => item.value === value),
        1,
      );
      const newSearchHistory = JSON.stringify(searchHistory);
      localStorage.setItem('searchHistory', newSearchHistory);
      setSearchHistory([...searchHistory]);
    },
    [searchHistory],
  );

  const searchHistoryitems = useMemo(() => {
    return searchHistory.map((item, i) => {
      if (item.type === 'label') {
        return (
          <FilterSearchItem
            key={i}
            type={item.type}
            value={item.value}
            label={{title: item.label.title, label: item.label.title}}
            onComplete={onComplete}
            onDelete={onDeleteHistoryItem}
          />
        );
      }
      return (
        <FilterSearchItem
          key={i}
          type={item.type}
          value={item.value}
          onComplete={onComplete}
        />
      );
    });
  }, [searchHistory, onComplete, onDeleteHistoryItem]);

  const highlightedLabelItems = useMemo(() => {
    return highlightedLabels.map((item, i) => {
      return (
        <FilterSearchItem
          key={i}
          type={'label'}
          value={item.title}
          label={item}
          onComplete={onComplete}
        />
      );
    });
  }, [highlightedLabels, onComplete]);

  return (
    <>
      <SearchToolbar onSearch={onInputChange} searchValue={searchValue} />
      <Bg bg="bg-white" />
      <Content toolbar={true} className="full-width">
        {searchHistory.length > 0 && (
          <div className="header">
            <span>Zoekgeschiedenis</span>
            <span className="delete" onClick={clearSearchHistory}>
              Wissen
            </span>
          </div>
        )}
        {searchHistoryitems}
        {searchValue.length <= 0 && (
          <>
            <div className="header">
              <span>Top categorieën</span>
            </div>
            {highlightedLabelItems}
          </>
        )}
        <div className="header">
          <span>Zoeksuggesties</span>
        </div>
        {searchResultItems}
      </Content>
    </>
  );
};

export default Search;
