export default class Formatters {
  static formatCurrency(
    inputInCents: number | string,
    includePrefix = true,
  ): string {
    // Ensure we only have digits and remove leading zeros
    const onlyDigits =
      `${inputInCents}`.replace(/\D/g, '').replace(/^0+/, '') || '0';

    let euros = '';
    let cents = '';

    // Handle special cases when the length is 1 or 2
    if (onlyDigits.length <= 2) {
      euros = '0';
      cents = onlyDigits.padStart(2, '0'); // Pads with leading zero if length is 1
    } else {
      euros = onlyDigits.slice(0, -2);
      cents = onlyDigits.slice(-2);
    }

    // Format the result
    const result = `${euros},${cents}`;
    return includePrefix ? `€${result}` : result;
  }

  static capitalize = (s: string): string => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  static truncate(string: string, length: number) {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  }
}
