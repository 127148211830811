import React, {useState, useEffect, useRef} from 'react';
import './category-select.style.scss';

type Props = {
  categories: Array<any>;
  selectedCategoryIndex: number;
  onChange?: (changedIndex: number) => void;
};

const CategorySelect: React.FC<Props> = ({
  categories,
  onChange,
  selectedCategoryIndex,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedCategoryIndex);
  const categoriesContainer = useRef<HTMLDivElement>(null);
  const categoryRefs = useRef(
    categories.map(() => React.createRef<HTMLDivElement>()),
  );

  useEffect(() => {
    if (categoryRefs.current.length !== categories.length) {
      categoryRefs.current = categories.map(() =>
        React.createRef<HTMLDivElement>(),
      );
    }
  }, [categories]);

  useEffect(() => {
    setSelectedIndex(selectedCategoryIndex);
  }, [selectedCategoryIndex]);

  useEffect(() => {
    // Scroll to selected category
    if (!categoryRefs.current || categoryRefs.current.length === 0) return;
    const selected = categoryRefs.current[selectedIndex].current;
    if (selected) {
      categoriesContainer.current?.scrollTo({
        left: selected.offsetLeft - 16,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [selectedIndex]);

  const onCategoryClicked = (index: number) => {
    setSelectedIndex(index);
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <div
      ref={categoriesContainer}
      className="category_select_container_wrapper">
      <div className="category_select_container">
        <div className="category_padding" />
        {categories.map((item, index) => (
          <div
            ref={categoryRefs.current[index]}
            key={index}
            className={`category_item ${
              selectedIndex === index ? 'selected' : ''
            }`}
            onClick={() => onCategoryClicked(index)}>
            {item.name}
          </div>
        ))}
        <div className="category_padding" />
      </div>
    </div>
  );
};

export default CategorySelect;
