import axios from 'axios';
import {Moment} from 'moment';
import APIResponse from './responses/APIResponse';
import GetReservedOrdersResponse from './responses/getReservedOrdersResponse';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export default class OrdersAPI {
  getReservedOrders = async (
    restaurantId: number,
    type: 'pickup' | 'delivery',
    date: Moment,
  ): Promise<APIResponse<GetReservedOrdersResponse>> => {
    const url = `${API_URL}/getReservedOrders`;
    const response = await axios.get(url, {
      params: {
        restaurantId,
        type,
        date: date.unix(),
      },
    });
    return new APIResponse(response.data, GetReservedOrdersResponse);
  };
}
