import axios from 'axios';
import Profile from '../../models/profile';
import APIResponse from './responses/APIResponse';
import GetProfileResponse from './responses/getProfileResponse';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export default class ProfileAPI {
  get = async (token: string): Promise<APIResponse<GetProfileResponse>> => {
    const url = `${API_URL}/profile`;
    const response = await axios.get(url, {
      headers: {Authorization: `Bearer ${token}`},
    });
    return new APIResponse(response.data, GetProfileResponse);
  };

  update = async (
    token: string,
    profile: Profile,
  ): Promise<APIResponse<void>> => {
    const url = `${API_URL}/profile/update-profile`;
    const response = await axios.put(url, {
      lw_token: token,
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      optIn: profile.optIn,
      phoneNumber: profile.phoneNumber,
      city: profile.city,
      street: profile.street,
      postalCode: profile.postalCode,
      houseNumber: profile.houseNumber,
      comment: profile.comment,
    });
    return new APIResponse(response.data);
  };

  delete = async (token: string, email: string) => {
    const url = `${API_URL}/user`;
    const response = await axios.delete(url, {
      headers: {Authorization: `Bearer ${token}`},
    });
    return new APIResponse(response.data);
  };
}
