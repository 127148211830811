import { showNotification } from '../actions/notifications.actions';
import api from '../../services/api/apiv2';
import { clearOrders, restoreOrdersAction } from '../actions/orders.actions';
import SessionType from '../../models/session-type';
import { AppThunk } from '../reducers/root.reducer';
import Session from '../../models/session';
import {
  setSession,
  restoreSessionAction,
  removeSessionAction,
  updateSessionDataAction,
  setSessionChangeTokenAction,
  updateSessionStatusAction,
  updateUserGreetingState,
} from './session.actions';
import { SessionJSON } from '../../models/json/session.json';
import Logger from '../../utils/logger';
import { NotificationType } from '../../models/notification';

export const restoreSession =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(restoreSessionAction());
    dispatch(restoreOrdersAction());
  };

export const setOrderSession =
  (
    restaurantId: number,
    restaurantToken: string,
    userToken: string,
  ): AppThunk<Promise<string | null>> =>
    async (dispatch) => {
      const response = await api.session.createOrder(
        restaurantId,
        restaurantToken,
        userToken,
      );
      if (response) {
        await dispatch(clearOrders());
        await dispatch(
          setSession({ sessionType: SessionType.FROM_HOME, token: response ?? '', restaurantId: Number(restaurantId) }),
        );
      } else {
        dispatch(showNotification('Er ging iets mis!', NotificationType.ERROR));
      }

      return response;
    };

export const setTableSession =
  (
    data: any,
  ): AppThunk<Promise<Session | null>> =>
    async (dispatch) => {
      if (data) {
        const sessionType = SessionType.RESTAURANT;
        const session = Session.fromJoinSessionResponse(
          data,
          sessionType,
        );
        await dispatch(clearOrders());
        await dispatch(setSession(session));
        await dispatch(updateSessionStatusAction('open'));

        return session;
      } else {
        dispatch(showNotification('Er ging iets mis!', NotificationType.ERROR));
        return null;
      }
    };

export const setSessionChangeToken =
  (token: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
      dispatch(setSessionChangeTokenAction(token));
    };

export const updateSessionData =
  (session: SessionJSON): AppThunk<Promise<void>> =>
    async (dispatch) => {
      Logger.log(session);
      dispatch(updateSessionDataAction(session));
    };

export const updateSessionStatus =
  (newStatus: string): AppThunk<Promise<void>> =>
    async (dispatch) => {
      console.log('Updating session status to: ', newStatus);
      await dispatch(updateSessionStatusAction(newStatus));
    };

export const setUserGreeted =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    await dispatch(updateUserGreetingState(true));
  };

export const removeSession =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    await dispatch(clearOrders());
    await dispatch(removeSessionAction());
  };

/**
 * Cancels a session in the API. To be used when the user doesn't want to finish a session he/she is in and will only result in a success when no payment is outstanding.
 * @param sessionToken Session to cancel
 */
export const cancelSession =
  (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    return true;
  };
