import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import { showNotification } from '../../redux/actions/notifications.actions';
import Toolbar from '../../components/toolbar/toolbar.component';
import Content from '../../components/content/content.component';
import queryString from 'query-string';
import { Component } from 'react';
import './change-password.styles.scss';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../services/api';
import { connect } from 'react-redux';
import Bg from '../../utils/bg';
import { NotificationType } from '../../models/notification';
import { Button, Input, InputGroup, Label } from '../../components/ui-base';


type Props = {
  loggedIn: boolean;
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => void;
} & RouteComponentProps;

type State = {
  loading: boolean;
  password: string;
  passwordVerify: string;
};

class ChangePassword extends Component<Props, State> {
  state = {
    password: '',
    passwordVerify: '',
    loading: false,
  };

  componentDidMount() {
    const parsedQuery = queryString.parse(this.props.location.search);
    if (!parsedQuery.t || !parsedQuery.email) this.props.history.push('/home'); 
  }

  resetPassword = async () => {
    const { password, passwordVerify } = this.state;
    if (password.length === 0) {
      this.props.showNotification(
        'Vul een wachtwoord in',
        NotificationType.ERROR,
      );
      return;
    }

    if (password.length < 6) {
      this.props.showNotification(
        'Het wachtwoord moet minimaal 6 tekens bevatten',
        NotificationType.ERROR,
      );
      return;
    }

    if (password !== passwordVerify) {
      this.props.showNotification(
        'De ingevulde wachtwoorden komen niet overeen',
        NotificationType.ERROR,
      );
      return;
    }

    this.setState({ loading: true });

    const parsedQuery = queryString.parse(this.props.location.search);

    const success = await api.changePassword(
      password,
      parsedQuery.t,
    );

    this.setState({ loading: false });

    if (success) {
      this.props.showNotification(
        'Wachtwoord aangepast, u kunt nu inloggen!',
        NotificationType.SUCCESS,
        5000,
      );
      this.props.history.push('/login');
    } else {
      this.props.showNotification(
        'Wachtwoord resetten mislukt.',
        NotificationType.ERROR,
      );
    }
  };

  render() {
    const { loggedIn } = this.props;
    const { loading, password, passwordVerify } = this.state;

    if (loggedIn) {
      setTimeout(() => this.props.history.replace('/profile'), 0);
      return <></>;
    }

    return (
      <>
        <Toolbar backLink="/login" title="Wachtwoord vergeten" />
        <Bg bg="bg-white" />

        <Content toolbar={true}>
          <div style={{ width: '100%' }} className="login_header" />

          <LoadingIndicator isLoading={loading} />
          {!loading && (
            <div className="forgot_password_content full-width">
              <InputGroup>
                <Label>Nieuw wachtwoord</Label>
                <Input
                  type="password"
                  placeholder="Wachtwoord"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </InputGroup>
              <InputGroup>
                <Label>Wachtwoord bevestigen</Label>
                <Input
                  type="password"
                  placeholder="Wachtwoord bevestigen"
                  value={passwordVerify}
                  onChange={e => this.setState({ passwordVerify: e.target.value })}
                />
              </InputGroup>

              <Button
                text="Wachtwoord veranderen"
                className="mt-20"
                onClick={this.resetPassword}
              />
            </div>
          )}
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loggedIn: state.account.loggedIn,
  };
};

export default connect(mapStateToProps, { showNotification })(ChangePassword);
