import axios from 'axios';
import APIResponse from './responses/APIResponse';
import {Moment} from 'moment';
import ValidateReservationResponse from './responses/validateReservationResponse';
import {ReservationData} from '../../screens/restaurant/reservation-dialog/reservation-interfaces';
import {DateTime} from 'luxon';
import Table from '../../models/table';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export default class ReservationsAPI {
  generateTimeStampFromTimeAndDate = (time: string, date: Date) => {
    const timeArray = time?.split(':');
    if (timeArray?.length !== 2) return null;

    return DateTime.fromJSDate(date)
      .set({
        hour: parseInt(timeArray[0], 10),
        minute: parseInt(timeArray[1], 10),
        second: 0,
      })
      .toUnixInteger();
  };

  getTables = async (restaurantId: number): Promise<Table[]> => {
    try {
      const response = await axios.get(
        API_URL + `/reservations/${restaurantId}/tables`,
      );

      if (response.data.success && response.data.tables) {
        return response.data.tables.map((table: any) => new Table(table));
      }
    } catch (error) {
      return [];
    }
    return [];
  };

  getReservations = async (restaurantId: number, date: Moment) => {
    try {
      const response = await axios.get(
        API_URL + `/reservations/${restaurantId}?date=${date.unix()}`,
      );
      if (response.data.reservations && response.data.success) {
        return response.data;
      }
    } catch (error) {
      return null;
    }
    return null;
  };

  validate = async (code: string) => {
    try {
      const response = await axios.get(
        API_URL + '/reservations/validate?code=' + code,
      );
      return new APIResponse(response.data, ValidateReservationResponse);
    } catch (error) {
      throw error;
    }
  };

  makeNew = async (restaurantId: number, reservationData: ReservationData) => {
    try {
      const timestamp = this.generateTimeStampFromTimeAndDate(
        reservationData.reservationTime ?? '',
        reservationData?.reservationDate ?? new Date(),
      );
      const body = {
        place: reservationData.preferredLocation,
        name: reservationData.customerDetails.name,
        phoneNumber: reservationData.customerDetails.phoneNumber,
        email: reservationData.customerDetails.email,
        people: reservationData.amountOfPersons,
        timestamp: timestamp,
      };

      const response = await axios.post(
        `${API_URL}/reservations/${restaurantId}`,
        body,
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };
}
