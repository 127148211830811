import {
  Dialog,
  ModalDialogDescription,
  ModalDialogTitle,
} from '../../../components/ui-base';

import './entry-ticket.styles.scss';
import LabelValueGroup from '../../../components/ui-base/label-value-group/label-value.component';
import ValidateReservationResponse from '../../../services/api/responses/validateReservationResponse';
import Lottie from 'react-lottie-player';
import HapstapAnimation from '../../../assets/animations/hapstap.json';
import Backdrop from '../../../components/backdrop/backdrop.component';
import Bg from '../../../utils/bg';

type Props = {
  validationData: ValidateReservationResponse;
  code: string;
};

const ReservationEntryTicket: React.FC<Props> = ({
  validationData,
  code,
}: Props) => {
  return (
    <div className="reservation-entry-ticket">
      <div className="header-image">
        <Lottie animationData={HapstapAnimation} />
      </div>
      <Bg />
      <Backdrop />
      <Dialog>
        <ModalDialogTitle className="restaurant-name">
          RESERVERINGSTICKET
        </ModalDialogTitle>
        <div className="reservation-data">
          <LabelValueGroup
            highlighted
            label="Datum"
            value={validationData.datetime.format('DD-MM-YYYY')}
          />
          <LabelValueGroup
            highlighted
            label="Tijd"
            value={`${validationData.datetime.format(
              'HH:mm',
            )} - ${validationData.endDatetime.format('HH:mm')}`}
          />
          <LabelValueGroup label="Bij" value={validationData.restaurantTitle} />
          <LabelValueGroup
            label="Adres"
            value={validationData.restaurantAddress}
          />
          <LabelValueGroup label="Naam" value={validationData.name} />
          <LabelValueGroup
            label="Aantal personen"
            value={validationData.numberOfPeople.toString()}
          />
          <LabelValueGroup
            label="Code"
            value={code}
            onValueClick={() => {
              window.location.reload();
            }}
          />
          <ModalDialogDescription className="ticket-extra-information">
            Laat dit ticket aan de poort zien en u heeft vanaf een half uur
            vooraf aan uw reservering direct toegang tot de locatie.
          </ModalDialogDescription>
        </div>
      </Dialog>
    </div>
  );
};

export default ReservationEntryTicket;
