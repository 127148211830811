import axios from 'axios';
import RestaurantLabel from '../models/restaurantLabel';
import RestaurantMenuItem from '../models/restaurantMenuItem';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

async function getMenuCategories(restaurantSlug) {
  try{
    const url = `${API_URL}/restaurants/${restaurantSlug}/categories`;
    const response = await axios.get(url);
    const data = response.data;
    if (data.success && data.categories) {
      return data.categories;
    }
  }catch (e) {}
  return [];
}

async function getMenu(restaurantSlug, isPreview) {
  try{
    let url = `${API_URL}/restaurants/${restaurantSlug}/menu`;
    if (isPreview) {
      url += '?preview=lwpv';
    }
    const response = await axios.get(url);
    const data = response.data;
    if (data.success && data.menuItems) {
      return data.menuItems.map((it) => new RestaurantMenuItem(it));
    }
  }catch (e){}
  return [];
}

//TODO: New API
async function searchMenu({search, menuItem, pricing, city}) {
  const searchParams = [];
  if (menuItem) {
    searchParams.push('menuItem=' + menuItem);
  }
  if (search) {
    searchParams.push('search=' + search);
  }
  if (pricing) {
    searchParams.push('pricing=' + pricing);
  }
  if (city) {
    searchParams.push('city=' + city);
  }
  let queryParam = '';
  if (searchParams.length > 0) {
    queryParam = '?' + searchParams.join('&');
  }
  try{
    const url = `${API_URL}/getMenuItems${queryParam}`;
    const response = await axios.get(url);
    const data = response.data;
    if (data.success && data.data) {
      return data.data;
    }
  }catch (e) {}
  return null;
}

//TODO: New API
async function callOber(sessionId, sessionToken, type, tips) {
  let url = `${API_URL}/callOber/?sessionId=${encodeURIComponent(
    sessionId,
  )}&sessionToken=${encodeURIComponent(sessionToken)}&request=${type}`;
  if (tips !== undefined) {
    url += `&tips=${tips}`;
  }
  const response = await axios.get(url);
  return response.data;
}

// this api call is used to get a menu item which could be inactive (?preview=lwpv)
async function getMenuItemDetails(restaurantSlug, menuItemId, isPreview) {
  let url = `${API_URL}/restaurants/${restaurantSlug}/menu/${menuItemId}`;
  if (isPreview) {
    url += '?preview=lwpv';
  }
  const response = await axios.get(url);
  if (response.data.success) {
    return response.data.menuItem;
  }

  return null;
}

async function getLabels() {
  try{
    const url = `${API_URL}/restaurants/labels`;
    const response = await axios.get(url);

    if (response.data.success && response.data.labels) {
      return response.data.labels.map((x) => new RestaurantLabel(x));
    }
  }catch (e) {

  }
  return [];
}

//TODO: New API join table
async function createSession(restaurantId, userToken) {
  const url = `${API_URL}/createSession`;

  const data = {
    restaurantId: restaurantId,
  };

  if (userToken && userToken.length > 0) {
    data.lw_token = userToken;
  }

  const response = await axios.post(url, data);

  if (response.data.success) {
    return response.data.data;
  }

  return null;
}

async function addOrder(
  sessionToken,
  itemId,
  sideDishesSelections,
  comment,
  amount = 2,
) {
  try{
    const url = `${API_URL}/orders/add`;
    const response = await axios.put(url, {
      sessionToken: sessionToken,
      itemId: itemId,
      sideDishesSelections: sideDishesSelections,
      comment: comment,
      amount: amount,
    });

    if (response.data.success) {
      return response.data;
    }
  }catch (e) {
    
    return e.response.data.message;
  }

  return null;
}

async function updateOrder(sessionToken, orderId, amount) {
  try{
    const url = `${API_URL}/orders/update`;

    const response = await axios.put(url, {
      sessionToken: sessionToken,
      orderId: orderId,
      amount: amount,
    });

    if (response.data.success) {
      return response.data;
    }
  }catch (e) {
    
    return e.response.data.message;
  }
  return null;
}

async function submitOrders(sessionToken, extraOptions) {
  const url = `${API_URL}/orders/submit`;

  let params = {
    sessionToken: sessionToken,
  };

  if (extraOptions) {
    params = Object.assign({}, params, extraOptions);
  }

  const response = await axios.post(url, params);

  if (response.data.success) {
    return {...response.data, success: 1};
  }

  if (response.data.error) {
    return response.data;
  }

  return null;
}

async function login(email, password) {
  const url = `${API_URL}/auth/login`;

  try{
    const response = await axios.post(url, {
      email: email,
      password: password,
    });
    const data = response.data;
    return {success: 1, data: data};
  }catch (e) {
    if (e.response.data.code === 'EmailNotVerified') {
      return 'email_not_verified';
    }

    if (e.response.data.code === 'InvalidCredentials') {
      return 'invalid_credentials';
    }
  }

  return null;
}

async function register(email, password, firstName, optInChecked) {
  try {
    const url = `${API_URL}/auth/register`;

    const response = await axios.post(url, {
      email: email,
      password: password,
      firstName: firstName,
      optIn: optInChecked,
    });
    if (response.data.success) {
      return 'success';
    }
  }catch (e) {
    if (e.response.data.code === 'EmailNotVerified') {
      return 'email_not_verified';
    }

    if(e.response.data.code === 'EmailExists'){
      return 'email_taken';
    }
  }
  return null;
}

async function changePassword(newPassword, resetToken) {
  try{
    const url = `${API_URL}/auth/password-reset`;

    const response = await axios.post(url, {
      password: newPassword,
      resetToken: resetToken,
    });
    return response.data.success;
  }catch (e) {
    return false;
  }
}

async function resetPassword(email) {
  try{
    const url = `${API_URL}/auth/password-recovery`;

    const response = await axios.post(url, {
      email: email,
    });

    return response.data.success;
  }catch (e) {
    return false;
  }
}

async function setAllergies(token, allergies) {
  try{
    const url = `${API_URL}/setAllergies`;

    const response = await axios.post(url, {
      lw_token: token,
      allergies: allergies,
    });

    return response.data.success;
  }catch (e) {
    return false;
  }
}

async function linkUserSession(userToken, sessionId, sessionToken) {
  try{
    const url = `${API_URL}/linkUserSession`;

    const response = await axios.post(url, {
      lw_token: userToken,
      sessionId: sessionId,
      sessionToken: sessionToken,
    });

    return response.data.success;
  }catch (e) {
    return false;
  }
}

async function addFavoriteRestaurant(token, restaurantId) {
  try{
    const url = `${API_URL}/profile/favorite-restaurants`;

    if (restaurantId === undefined || restaurantId === '') {
      return true;
    }

    const response = await axios.put(
        url,
        {
          restaurantId: restaurantId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
    );

    return response.data.success;
  }catch (e) {
    return false;
  }
}

async function removeFavoriteRestaurant(token, restaurantId) {
  try{
    const url = `${API_URL}/profile/favorite-restaurants`;

    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        restaurantId: restaurantId,
      },
    });

    return response.data.success;
  }catch (e) {
    return false;
  }
}

async function addFavoriteMenuItem(token, menuItemId) {
  try{
    const url = `${API_URL}/profile/favorite-menuitems`;

    if (menuItemId === undefined || menuItemId === '') {
      return true;
    }

    const response = await axios.put(
        url,
        {
          menuItemId: menuItemId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
    );

    return response.data.success;
  }catch (e) {
    return false;
  }
}

async function removeFavoriteMenuItem(token, menuItemId) {
  try{
    const url = `${API_URL}/profile/favorite-menuitems`;

    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        menuItemId: menuItemId,
      },
    });

    return response.data.success;
  }catch (e) {
    return false;
  }
}

async function uploadProfileImage(token, profileImage) {
  try{
    const url = `${API_URL}/uploadProfileImage`;

    const response = await axios.post(url, {
      lw_token: token,
      profileImage: profileImage,
    });

    if (response.data.success && response.data.data.token) {
      return {success: true, token: response.data.data.token};
    }
  }catch (e) {}

  return null;
}

async function getOrderHistory(token) {
  try{
    const url = `${API_URL}/getOrderHistory`;

    const response = await axios.post(url, {
      lw_token: token,
    });

    if (response.data.success) {
      return response.data.data;
    }
  }catch (e) {}
  return null;
}

async function paySession(sessionToken, paymentMethod, tips = 0) {
  try{
    const url = `${API_URL}/orders/pay`;
    const response = await axios.post(url, {
      paymentMethod: paymentMethod,
      sessionToken: sessionToken,
      tips: tips,
    });
    return response.data;
  }catch (e) {
    return e.response.data;
  }
}

async function recoverFailedSession(sessionId, sessionToken) {
  const url = `${API_URL}/recoverFailedSession`;
  const response = await axios.post(url, {
    sessionId: sessionId,
    sessionToken: sessionToken,
  });

  return response.data;
}
const api = {
  getMenu: getMenu,
  searchMenu: searchMenu,
  getMenuCategories: getMenuCategories,
  callOber: callOber,
  getLabels: getLabels,
  addOrder: addOrder,
  updateOrder: updateOrder,
  submitOrders: submitOrders,
  login: login,
  register: register,
  setAllergies: setAllergies,
  resetPassword: resetPassword,
  changePassword: changePassword,
  linkUserSession: linkUserSession,
  addFavoriteRestaurant: addFavoriteRestaurant,
  removeFavoriteRestaurant: removeFavoriteRestaurant,
  addFavoriteMenuItem: addFavoriteMenuItem,
  removeFavoriteMenuItem: removeFavoriteMenuItem,
  uploadProfileImage: uploadProfileImage,
  getOrderHistory: getOrderHistory,
  getMenuItemDetails: getMenuItemDetails,
  paySession: paySession,
  createSession: createSession,
  recoverFailedSession: recoverFailedSession,
  API_URL: API_URL,
};

export default api;
