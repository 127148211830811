import axios from 'axios';
import APIResponse from './responses/APIResponse';
import GetOccupationResponse from './responses/getOccupationResponse';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export default class OccupationAPI {
  get = async (): Promise<APIResponse<GetOccupationResponse>> => {
    const url = `${API_URL}/restaurants/occupation`;
    const {data} = await axios.get(url);
    return new APIResponse(data, GetOccupationResponse);
  };
}
