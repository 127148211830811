import './deliver.dialog.style.scss';
import DeliverContentDialog from './components/deliver-content.dialog.component';
import TakeoutContentDialog from './components/takeout-content.dialog.component';
import ConfirmationDialog from './components/confirmation.dialog.component';
import moment from 'moment';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';

import DialogImageHeader from '../../components/dialog-image-header/dialog-image-header';
import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import {showNotification} from '../../redux/actions/notifications.actions';
import {clearOrders, submitOrders} from '../../redux/actions/orders.actions';
import {getMenu} from '../../redux/actions/restaurant.menu.actions';
import {removeSession, updateSessionStatus,} from '../../redux/session/session.thunks';
import api from '../../services/api';
import TimeslotChecker from '../../services/timeslot-checker/timeslot-checker';
import TimeslotGenerator from '../../services/timeslotgenerator/timeslotgenerator';
import Logger from '../../utils/logger';
import {Button, ModalDialog, ModalDialogDescription, ModalDialogTitle,} from '../../components/ui-base';
import ChoiceContent from './components/choice-content.dialog.component';
import {AppState} from '../../redux/reducers/root.reducer';
import Restaurant from '../../models/restaurant';
import Timeslot from '../../models/timeslot';
import Session from '../../models/session';
import {NotificationType} from '../../models/notification';
import {MenuItem} from '../../components/toolbar/toolbar.component';
import {DateTime} from 'luxon';

type QueueItem = {
  details: {
    itemId: string;
    title: string;
    sideDishes: {[sideDish: string]: any};
    mainPrice: number;
  };
  status: string;
  amount: number;
};

type ReduxActions = {
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => void;
  removeSession: () => Promise<void>;
  updateSessionStatus: (newStatus: string) => Promise<void>;
  submitOrders: (sessionToken: string, extraOptions?: any) => Promise<any>;
  getMenu: (restaurantId: string, isPreview?: boolean) => void;
};

type ReduxProps = {
  menuItemsQueue: QueueItem[];
  restaurant: Restaurant | undefined;
  session: Session | null;
  menu: {[menuItem: string]: any};
};

type Props = {
  onCancel: () => void;
} & ReduxProps &
  ReduxActions &
  RouteComponentProps;

type State = {
  loading: boolean;
  showDeliverContent: boolean;
  showTakeoutContent: boolean;
  showConfirmOrder: boolean;
  showOrderCompleted: boolean;
  name: string;
  phone: string;
  streetname: string;
  housenumber: string;
  city: string;
  zipcode: string;
  email: string;
  donation: boolean;
  takeout: boolean;
  deliver: boolean;
  nameInvalid: boolean;
  phoneInvalid: boolean;
  streetInvalid: boolean;
  cityInvalid: boolean;
  zipcodeInvalid: boolean;
  housenumberInvalid: boolean;
  emailInvalid: boolean;
  deliveryTime: moment.Moment;
  tipValue: number;
  timeslots: Array<Timeslot>;
  paymentMethod: 'pin' | 'cash' | 'ideal';
};

class DeliverDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let paymentMethod = '' as 'pin' | 'cash' | 'ideal';
    if (props.restaurant?.pinEnabled) {
      paymentMethod = 'pin';
    } else if (props.restaurant?.cashEnabled) {
      paymentMethod = 'cash';
    } else if (props.restaurant?.iDealEnabled) {
      paymentMethod = 'ideal';
    }
    this.state = {
      loading: false,
      showDeliverContent: false,
      showTakeoutContent: false,
      showConfirmOrder: false,
      showOrderCompleted: false,
      name: '',
      phone: '',
      streetname: '',
      housenumber: '',
      city: '',
      zipcode: '',
      email: '',
      donation: false,
      takeout: false,
      deliver: false,
      nameInvalid: false,
      phoneInvalid: false,
      streetInvalid: false,
      cityInvalid: false,
      zipcodeInvalid: false,
      housenumberInvalid: false,
      emailInvalid: false,
      deliveryTime: moment(),
      tipValue: 0,
      timeslots: [],
      paymentMethod,
    };
  }

  _canUpgradeToToGo = () => {
    return false;
    const {menuItemsQueue} = this.props;
    const restaurantMenu = this._getMenu();
    const mealTypesInOrder = menuItemsQueue.map((queuedItem) => {
      const item = restaurantMenu.find(
        (menuItem: MenuItem) => queuedItem.details.itemId === menuItem.id,
      );
      if (item) {
        return item.mealTypes;
      }
      return ['to-go'];
    });

    return mealTypesInOrder.every((x) => x.includes('to-go'));
  };

  onDateChangeEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.valueAsDate) {
      const selectedMoment = moment(event.target.valueAsDate);

      this.setState(
        {
          deliveryTime: selectedMoment,
        },
        async () => await this._calculateTimeslots(),
      );
    }
  };

  _calculateTimeslots = async () => {
    const {restaurant} = this.props;
    const {deliveryTime, showDeliverContent} = this.state;

    // const orderReservations = await apiv2.orders.getReservedOrders(
    //   restaurant!.id,
    //   showDeliverContent ? 'delivery' : 'pickup',
    //   deliveryTime,
    // ); 

    let slots: Array<Timeslot> = [];

    const canUpgradeToGo = this._canUpgradeToToGo();
    let requiredMealtime: 'takeout' | 'to-go' | 'delivery';
    let interval: number;
    if (showDeliverContent) {
      requiredMealtime = 'delivery';
      interval = restaurant!.reservationTimeslotsDelivery;
    } else if (canUpgradeToGo) {
      requiredMealtime = 'to-go';
      interval = restaurant!.reservationTimeslotsToGo;
    } else {
      requiredMealtime = 'takeout';
      interval = restaurant!.reservationTimeslotsTakeout;
    }

    const mealTimes = restaurant?.getMealTime(
      requiredMealtime,
      DateTime.fromISO(deliveryTime.toISOString()),
    );

    const openingTimes = mealTimes;

    let generateForDate = DateTime.fromISO(deliveryTime.toISOString()).set({
      hour: 0,
      minute: 0,
      second: 0,
    });

    if (deliveryTime.isSame(moment(), 'date')) {
      generateForDate = DateTime.now().setZone('Europe/Amsterdam');
    }

    openingTimes?.forEach((openingTime) => {
      let generated = TimeslotGenerator.generateTimeslots(
        openingTime,
        interval,
        moment(generateForDate.toJSDate()),
        true,
      );

      if (requiredMealtime !== 'to-go') {
        generated = generated.filter((x) =>
          x.when.isAfter(moment().add(interval, 'minutes')),
        );
        // Remove the first entry so you can't order the instant the restaurant openend.
        //generated.shift();
        // Remove the last entry so you can't order the exact moment the restaurant would close.
        //generated.pop();
      }
      slots.push(...generated);
    });
    // Remove timeslots which are not for the selected date.
    // Remove this line if we want to show every timeslot defined in the openingtimes instead of per date. if we want to show everytime.
    slots = slots.filter((x) => x.when.isSame(deliveryTime, 'date'));

    // Retrieve openingtimes for the exact queried date
    const restaurantOpeningTimes = restaurant!.getOpeningTimesForDate(
      DateTime.fromISO(deliveryTime.format('YYYY-MM-DD')),
    );

    // Filter out all slots that supersede or exceed the opening time of the restaurant.
    // The adding and subtracting of minutes is in place so you can't place an order for the exact moment the restaurant opens/closes.
    //slots = []; //TODO: CHECK
    //slots.filter(
    //   (x) =>
    //     restaurantOpeningTimes.find(
    //       (openingTime) =>
    //         x.when.isSameOrAfter(*
    //           moment(openingTime.from), //.add(15, 'minutes'),
    //         ) &&
    //         x.when.isSameOrBefore(
    //           moment(openingTime.to), //.subtract(15, 'minutes'),
    //         ),
    //     ) !== undefined,
    // );
    const capacity = showDeliverContent
      ? restaurant!.reservationCapacityDelivery
      : restaurant!.reservationCapacityTakeout;
    slots.forEach((slot) => {
      slot.available = TimeslotChecker.checkTimeslotAvailabilityForOrders(
        slot,
        [], //TODO: Check reserved orders
        capacity,
      );
    });

    this.setState({timeslots: slots});
  };

  _getMenu = () => {
    const {restaurant} = this.props;

    if (restaurant) {
      const menu = this.props.menu[restaurant.generateSlug()];
      if (!menu) {
        this.props.getMenu(restaurant.generateSlug());
      }
      return menu;
    }

    return null;
  };

  render() {
    const {
      loading,
      showDeliverContent,
      showTakeoutContent,
      showOrderCompleted,
      showConfirmOrder,
      name,
      phone,
      streetname,
      city,
      zipcode,
      housenumber,
      email,
      donation,
      paymentMethod,
      nameInvalid,
      phoneInvalid,
      streetInvalid,
      cityInvalid,
      zipcodeInvalid,
      housenumberInvalid,
      emailInvalid,
      deliveryTime,
      timeslots,
      deliver,
      takeout,
      tipValue,
    } = this.state;

    const renderChoiceContent =
      !loading &&
      !showDeliverContent &&
      !showTakeoutContent &&
      !showOrderCompleted &&
      !showConfirmOrder;

    return (
      <ModalDialog
        center={
          !showDeliverContent &&
          !showTakeoutContent &&
          !showOrderCompleted &&
          !showConfirmOrder
        }>
        <DialogImageHeader />

        {renderChoiceContent && (
          <ChoiceContent
            onCancel={this.props.onCancel}
            menu={this._getMenu()}
            canUpgradeToToGo={this._canUpgradeToToGo}
            calculateTimeslots={(state: {}) =>
              this.setState(state, this._calculateTimeslots)
            }
          />
        )}
        {!loading && showDeliverContent && (
          <DeliverContentDialog
            name={name}
            phone={phone}
            streetname={streetname}
            city={city}
            zipcode={zipcode}
            housenumber={housenumber}
            email={email}
            donation={donation}
            paymentMethod={paymentMethod}
            nameInvalid={nameInvalid}
            phoneInvalid={phoneInvalid}
            streetInvalid={streetInvalid}
            cityInvalid={cityInvalid}
            zipcodeInvalid={zipcodeInvalid}
            housenumberInvalid={housenumberInvalid}
            emailInvalid={emailInvalid}
            deliveryTime={deliveryTime}
            timeslots={timeslots}
            updateState={(state) => this.setState(state)}
            onDateChangeEvent={this.onDateChangeEvent}
          />
        )}
        {!loading && showTakeoutContent && (
          <TakeoutContentDialog
            name={name}
            phone={phone}
            email={email}
            donation={donation}
            paymentMethod={paymentMethod}
            nameInvalid={nameInvalid}
            phoneInvalid={phoneInvalid}
            emailInvalid={emailInvalid}
            deliveryTime={deliveryTime}
            timeslots={timeslots}
            updateState={(state) => this.setState(state)}
            onDateChangeEvent={this.onDateChangeEvent}
          />
        )}
        {!loading && showConfirmOrder && (
          <ConfirmationDialog
            menu={this._getMenu()}
            name={name}
            phone={phone}
            streetname={streetname}
            city={city}
            zipcode={zipcode}
            housenumber={housenumber}
            email={email}
            donation={donation}
            deliver={deliver}
            takeout={takeout}
            paymentMethod={paymentMethod}
            tipValue={tipValue}
            deliveryTime={deliveryTime}
            updateState={(state) => this.setState(state)}
            submitOrders={this.submitOrders}
          />
        )}
        {!loading && showOrderCompleted && (
          <>
            <ModalDialogTitle className="mt-20">
              Bestelling voltooid
            </ModalDialogTitle>
            <ModalDialogDescription className="pay_dialog_description">
              Bedankt voor je bestelling!
            </ModalDialogDescription>
            <Button
              text="Sluiten"
              className="mt-40"
              onClick={this.props.onCancel}
            />
          </>
        )}

        <LoadingIndicator isLoading={loading} />
      </ModalDialog>
    );
  }

  submitOrders = async () => {
    const {history, session, restaurant} = this.props;
    const {token} = session!;
    this.setState({loading: true});

    const {
      deliveryTime,
      name,
      phone,
      streetname,
      city,
      zipcode,
      housenumber,
      takeout,
      deliver,
      email,
      donation,
      paymentMethod,
      tipValue,
    } = this.state;

    Logger.log(deliver, takeout);

    if (!paymentMethod) {
      this.setState({
        loading: false,
        showDeliverContent: deliver,
        showTakeoutContent: takeout,
        showConfirmOrder: false,
      });
      this.props.showNotification(
        'Kies een betaalmethode',
        NotificationType.ERROR,
      );
      return;
    }

    const canUpgradeToGo = this._canUpgradeToToGo();
    let orderType;
    if (!takeout) {
      orderType = 'delivery';
    } else if (canUpgradeToGo) {
      orderType = 'to-go';
    } else {
      orderType = 'pickup';
    }

    const extraOptions = {
      orderType: orderType,
      phoneNumber: phone,
      street: streetname,
      houseNumber: housenumber,
      postalCode: zipcode,
      city: city,
      name: name,
      email: email,
      donations: donation ? 100 : 0,
      readyAt: deliveryTime.unix(),
    };

    const response = await this.props.submitOrders(token, extraOptions);
    this.setState({loading: false});
    if (response.success) {
      this.setState({
        showDeliverContent: false,
        showTakeoutContent: false,
        showConfirmOrder: false,
        showOrderCompleted: true,
      });

      history.push('/restaurant/' + restaurant?.generateSlug());

      const data = await api.paySession(token, paymentMethod, tipValue);
        if (data.paymentUrl) {
          await this.props.updateSessionStatus('ideal');
          window.location.href = data.paymentUrl;
        } else {
          this.props.showNotification(
            'Je bestelling is geplaatst, bedankt!',
            NotificationType.SUCCESS,
          );
          this.props.removeSession();
        }
    } else if (
      response.error === 'breakfast_closed' ||
      response.error === 'lunch_closed' ||
      response.error === 'dinner_closed' ||
      response.error === 'snacks_closed' ||
      response.error === 'takeout_closed' ||
      response.error === 'delivery_closed'
    ) {
      const errors: {[type: string]: string} = {
        breakfast_closed: 'ontbijt',
        lunch_closed: 'lunch',
        dinner_closed: 'diner',
        snack_closed: 'snacks',
        takeout_closed: 'afhaal',
        delivery_closed: 'bezorg',
      };
      this.props.showNotification(
        `U kunt op dit moment geen ${
          errors[response.error]
        } gerechten bestellen.`,
        NotificationType.ERROR,
      );
    } else if (response.data.data) {
      const {data} = response.data;
      // First set default values
      this.setState({
        showDeliverContent: deliver,
        showTakeoutContent: takeout,
        showConfirmOrder: false,
      });

      data.forEach(({param}: any) => {
        switch (param) {
          case 'name':
            this.setState({nameInvalid: true});
            break;
          case 'phone':
            Logger.log('setting name to invalid');
            this.setState({phoneInvalid: true});
            break;
          case 'street':
            this.setState({streetInvalid: true});
            break;
          case 'houseNumber':
            this.setState({housenumberInvalid: true});
            break;
          case 'postalCode':
            this.setState({zipcodeInvalid: true});
            break;
          case 'email':
            this.setState({emailInvalid: true});
            break;
          case 'city':
            this.setState({cityInvalid: true});
            break;
          default:
            break;
        }

        this.props.showNotification(
          'Controlleer of alle velden correct zijn ingevuld',
          NotificationType.ERROR,
        );
      })

    //   this.setState({
    //     nameInvalid: true,
    //     showDeliverContent: deliver,
    //     showTakeoutContent: takeout,
    //     showConfirmOrder: false,
    //   });
    // } else if (response.message === 'The given phoneNumber is not valid.') {
    //   this.setState({
    //     phoneInvalid: true,
    //     showDeliverContent: deliver,
    //     showTakeoutContent: takeout,
    //     showConfirmOrder: false,
    //   });
    //   this.props.showNotification(
    //     'Controleer of het telefoonnummer correct is ingevuld',
    //     NotificationType.ERROR,
    //   );
    // } else if (response.message === 'street must be filled in.') {
    //   this.setState({
    //     streetInvalid: true,
    //     showDeliverContent: deliver,
    //     showTakeoutContent: takeout,
    //     showConfirmOrder: false,
    //   });
    //   this.props.showNotification(
    //     'Controleer of de straatnaam correct is ingevuld',
    //     NotificationType.ERROR,
    //   );
    // } else if (response.message === 'houseNumber must be filled in.') {
    //   this.setState({
    //     housenumberInvalid: true,
    //     showDeliverContent: deliver,
    //     showTakeoutContent: takeout,
    //     showConfirmOrder: false,
    //   });
    //   this.props.showNotification(
    //     'Controleer of het huisnummer correct is ingevuld',
    //     NotificationType.ERROR,
    //   );
    // } else if (response.message === 'The given postalCode is not valid.') {
    //   this.setState({
    //     zipcodeInvalid: true,
    //     showDeliverContent: deliver,
    //     showTakeoutContent: takeout,
    //     showConfirmOrder: false,
    //   });
    //   this.props.showNotification(
    //     'Controleer of de postcode correct is ingevuld',
    //     NotificationType.ERROR,
    //   );
    // } else if (response.message === 'The given email is not valid.') {
    //   this.setState({
    //     emailInvalid: true,
    //     showDeliverContent: deliver,
    //     showTakeoutContent: takeout,
    //     showConfirmOrder: false,
    //   });
    //   this.props.showNotification(
    //     'Controleer of je e-mailadres correct is ingevuld',
    //     NotificationType.ERROR,
    //   );
    // } else if (response.message === 'city must be filled in.') {
    //   this.setState({
    //     cityInvalid: true,
    //     showDeliverContent: deliver,
    //     showTakeoutContent: takeout,
    //     showConfirmOrder: false,
    //   });
    //   this.props.showNotification(
    //     'Controleer of de stad correct is ingevuld',
    //     NotificationType.ERROR,
    //   );
    } else if (
      response.message ===
      'Kitchen is closed. Please remove all food items before submitting.'
    ) {
      this.props.showNotification(
        'De keuken is op dit moment gesloten',
        NotificationType.ERROR,
      );
    } else if (response.error === 'invalidReservationDate') {
      this.props.showNotification(
        'Controleer de ingevoerde datum en tijd',
        NotificationType.ERROR,
      );
      this.setState({
        showDeliverContent: deliver,
        showTakeoutContent: takeout,
        showConfirmOrder: false,
      });
    } else if (response.error === 'invalidReservationTime') {
      this.props.showNotification(
        'Aangegeven besteltijd is verstreken, selecteer een nieuwe tijd.',
        NotificationType.ERROR,
      );
      this.setState({
        showDeliverContent: deliver,
        showTakeoutContent: takeout,
        showConfirmOrder: false,
      });
    } else if (response.error === 'optionFull') {
      this.props.showNotification(
        'Er is geen plek meer in het gekozen tijdslot, kies een andere tijd.',
        NotificationType.ERROR,
      );
      this.setState({
        showDeliverContent: deliver,
        showTakeoutContent: takeout,
        showConfirmOrder: false,
      });
    } else if (response.error === 'noDelivery') {
      this.props.showNotification(
        'Wij bezorgen helaas niet op deze postcode.',
        NotificationType.ERROR,
      );
      this.setState({
        showDeliverContent: deliver,
        showTakeoutContent: takeout,
        showConfirmOrder: false,
      });
    } else {
      this.props.showNotification(
        'Er ging iets mis, probeer het later opnieuw.',
        NotificationType.ERROR,
      );
    }
  };
}

const mapStateToProps = (state: AppState) => {
  return {
    account: state.account,
    session: state.session.session,
    orders: state.orders,
    menuItemsQueue: state.orders.menuItemsQueue,
    menu: state.restaurantMenu,
    restaurant: state.restaurants.detailRestaurant ?? undefined,
  };
};

export default connect(mapStateToProps, {
  showNotification,
  removeSession,
  clearOrders,
  submitOrders,
  updateSessionStatus,
  getMenu,
})(withRouter(DeliverDialog));
