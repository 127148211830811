import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Button from '../ui-base/button/button.component';
import './restaurant.details.style.scss';
import React, {Component} from 'react';
import moment from 'moment';
import PriceRange from '../price-range/price-range.component';
import { DateTime } from 'luxon';

export default class RestaurantDetails extends Component {
  state = {
    showingOpeningTimes: false,
  };

  render() {
    const {visible} = this.props;

    return (
      <TransitionGroup>{visible && this._renderDetails()}</TransitionGroup>
    );
  }

  _renderDetails = () => {
    const {
      restaurant,
      hideDetails,
      occupation,
    } = this.props;

    const {
      description,
      pricing,
      companyPhoneNumber,
    } = restaurant;

    const animations = {
      enter: 'enter',
      enterActive: 'enter_active',
      exit: 'exit',
      exitActive: 'exit_active',
    };

    return (
      <CSSTransition timeout={300} classNames={animations}>
        <div className="restaurant_details">
          <div className="restaurant_details_separator mt-20" />
          <span className="restaurant_details_price_range">
            {this._renderPriceRange(pricing)}
          </span>
          <p className="restaurant_details_description">
            {description.split('\n').map((item, index) => (
              <span key={index}>
                {item}
                <br />
              </span>
            ))}
          </p>
          <div className="restaurant_details_separator" />
          <span className="restaurant_details_information mt-20">
            Keuken drukte:{' '}
            <span className="value">{this._getCrowdedDescription()}</span>
          </span>
          <span className="restaurant_details_information">
            Bezetting:{' '}
            <span className="value">{`${occupation.occupants}/${occupation.numberOfTables}`}</span>
          </span>
          <span className="restaurant_details_information">
            Telefoon:{' '}
            <span className="value" onClick={this.makeReservation}>
              {companyPhoneNumber}
            </span>
          </span>
          <div className="restaurant_details_opening_times">
            <span className="restaurant_details_information mb-20">
              Openingstijden:{' '}
            </span>
            {this._renderOpeningTimes()}
          </div>
          {companyPhoneNumber && companyPhoneNumber.length > 0 && (
            <Button
              className="ml-16 mr-16 mb-20"
              text="Bellen"
              onClick={this.makeReservation}
            />
          )}

          {hideDetails && (
            <ArrowIcon
              className="restaurant_details_arrow_up"
              fill="#222D39"
              onClick={hideDetails}
            />
          )}
        </div>
      </CSSTransition>
    );
  };

  makeReservation = () => {
    window.location = `tel:${this.props.restaurant.companyPhoneNumber}`;
  };

  _renderPriceRange = (amount) => {
    return (
      <div className="restaurant_details_price_range_container">
        <PriceRange value={amount} />
      </div>
    );
  };

  _getCurrentWeek = () =>{
      const currentDate = moment();
      const weekStart = currentDate.clone().startOf('isoWeek');
      const days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
      }
      return days;
  }

  _renderOpeningTimes = () => {
    let times = [];

    const { isOpen } = this.props;
    const {showingOpeningTimes} = this.state;
    

    if (!showingOpeningTimes) {
      // show if opened or closed
      if (isOpen) {
        times.push(
          <li className="open" key={1}>
            Geopend
            <ArrowIcon
              className="restaurant_details_arrow_opening_times_down"
              fill="#222D39"
              onClick={this.showOpeningTimes}
            />
          </li>,
        );
      } else {
        times.push(
          <li className="closed" key={1}>
            Gesloten
            <ArrowIcon
              className="restaurant_details_arrow_opening_times_down"
              fill="#222D39"
              onClick={this.toggleOpeningTimes}
            />
          </li>,
        );
      }
    } else {
      const {restaurant} = this.props;
      const openingTimes = restaurant.openingTimes;

      const filteredOpeningTimes = openingTimes.filter((time) => time.type === 'restaurant');
      const localized = [
      'maandag',
      'dinsdag',
      'woensdag',
      'donderdag',
      'vrijdag',
      'zaterdag',
      'zondag',
      ];

      const days = Object.keys(openingTimes);

      const weekdays=this._getCurrentWeek();

      const currentDate = DateTime.now().setZone('Europe/Amsterdam');
      const currDay = currentDate.toFormat('cccc').toLowerCase();

      times = filteredOpeningTimes
          .map((day, index)=>{
        const classDay =
          localized[day.day] === currDay
            ? 'restaurant_details_day today'
            : 'restaurant_details_day';
        const classTime =
          localized[day.day] === currDay
            ? 'restaurant_details_time today'
            : 'restaurant_details_time';

        let closedThisDay = (restaurant.closedOn && restaurant.closedOn.includes(currentDate.toFormat('dd-mm-yyyy'))) || (restaurant.exceptionClosedOn && restaurant.exceptionClosedOn.includes(weekdays[index]));

        if(restaurant.exceptionOpenOn && restaurant.exceptionOpenOn.includes(weekdays[index])){
          closedThisDay = false;
        }
        
        return (     
               <li key={day.day}>
            <div className={classDay}>{`${localized[index]}`}</div>
            <span className={classTime}>
              {closedThisDay
                ? 'Gesloten'
                : `${DateTime.fromISO(day.from).toFormat('HH:mm')}-${DateTime.fromISO(day.to).toFormat('HH:mm')}`}
            </span>
            {index === days.length - 1 && (
              <ArrowIcon
                className="restaurant_details_arrow_opening_times_down up"
                fill="#222D39"
              />
            )}
          </li>)
      })
      moment.locale('nl');
    }

    return (
      <ul
        className="restaurant_details_openings_times_list"
        onClick={this.toggleOpeningTimes}>
        {times}
      </ul>
    );
  };

  toggleOpeningTimes = () => {
    this.setState({showingOpeningTimes: !this.state.showingOpeningTimes});
  };

  _getCrowdedDescription = () => {
    const { restaurant } = this.props;
    const vals = { 1: 'Rustig', 2: 'Gemiddeld', 3: 'Druk' };
    const val = vals[restaurant.crowded];
    return val ? val : 'onbekend';
  };
}
