import {AppThunk} from '../reducers/root.reducer';
import Table from '../../models/table';
import api from '../../services/api/apiv2';
import {tablesLoadedAction} from './reservations.actions';

export const loadTables =
  (restaurantId: number): AppThunk<Promise<Array<Table> | null>> =>
  async (dispatch) => {
    try {
      const apiResponse = await api.reservations.getTables(restaurantId);
      dispatch(tablesLoadedAction(restaurantId, apiResponse));
      return apiResponse;
    } catch (error) {
      //TODO: Handle error
    }
    return null;
  };
