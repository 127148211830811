import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import AltAction from '../../../components/alt-action/alt.action.component';
import {Button, Input, InputGroup, Label} from '../../../components/ui-base';
import HapstapAnimation from '../../../assets/animations/hapstap.json';
import './ticket-form.styles.scss';
import Lottie from 'react-lottie-player';

type Props = {
  onCodeChanged: (code: string) => void;
};

const TicketForm: React.FC<Props> = ({onCodeChanged}: Props) => {
  const history = useHistory();
  const [code, setCode] = useState('');
  return (
    <div className="ticket-form">
      <div className="header-image">
        <Lottie animationData={HapstapAnimation} />
      </div>

      <h2>Reserveringsticket activeren</h2>
      <p>
        Voer hieronder jouw persoonlijke token van je reservering in. Deze kun
        je vinden in de bevestigingsmail die je van de reservering hebt
        ontvangen.
      </p>

      <InputGroup>
        <Label>Token</Label>
        <Input
          type="text"
          value={code}
          placeholder="00 00 00 00"
          onChange={(event) => {
            setCode(event.target.value.trim());
          }}
        />
      </InputGroup>
      <Button
        text="Bevestigen"
        onClick={() => {
          history.push({pathname: 'reservation', search: `code=${code}`});
          onCodeChanged(code);
        }}
      />
      <AltAction
        text="Annuleren"
        onClick={() => {
          history.replace('/restaurants');
        }}
        icon="close"
      />
    </div>
  );
};

export default TicketForm;
