import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import _ from "lodash";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import LoadingIndicator from "../../components/loading-indicator/loading.indicator.component";
import CategoryHeader from "../../components/category-header/category.header.component";
import TableHeader from "../../components/table-header/table.header.component";
import YesNoDialog from "../../sections/yes-no-dialog/yes.no.dialog.component";
import OrderTotal from "../../components/order-total/order.total.component";
import OrderItem from "../../components/order-item/order.item.component";
import PayDialog, { PaymentMethod } from "../../sections/pay-dialog/pay.dialog.component";
import DeliverDialog from "../../sections/remote-order/deliver.dialog.component";
import MessageDialog from "../../sections/message-dialog/message.dialog.component";
import Content from "../../components/content/content.component";
import Toolbar from "../../components/toolbar/toolbar.component";
import Button from "../../components/ui-base/button/button.component";
import AltAction from "../../components/alt-action/alt.action.component";
import SessionType from "../../models/session-type";
import OrderConfirmationDialog from "../../components/order-confirmation-dialog/order-confirmation-dialog.component";
import { Typography } from "../../components/ui-base";
import { NotificationType } from "../../models/notification";
import Formatters from "../../utils/formatters";
import { ServerEvents, useSocketServerConnection } from "../../utils/hooks/use-socket-server-connection.hook";
import { AppState } from "../../redux/reducers/root.reducer";
import HistoricOrder from "../../models/history/historicOrder";
import { showNotification } from "../../redux/actions/notifications.actions";
import { addSubmittedOrder, changeMenuItemAmount, clearOrders } from "../../redux/actions/orders.actions";
import { removeSession, updateSessionStatus } from "../../redux/session/session.thunks";
import { getRestaurant } from "../../redux/actions/restaurants.actions";
import { getMenu } from "../../redux/actions/restaurant.menu.actions";
import api from "../../services/api";

type Props = RouteComponentProps;

const Orders: React.FC<Props> = () => {
  // Selecting state from Redux store
  const menuItemsQueue = useSelector((state: AppState) => state.orders.menuItemsQueue);
  const menuItemsOrdered = useSelector((state: AppState) => state.orders.menuItemsOrdered);
  const menu = useSelector((state: AppState) => state.restaurantMenu);
  const hasSession = useSelector((state: AppState) => state.session.hasSession);
  const session = useSelector((state: AppState) => state.session.session);
  const paymentStatus = useSelector((state: AppState) => state.session?.paymentStatus);
  const restaurants = useSelector((state: AppState) => state.restaurants.restaurants);
  const restaurant = useSelector((state: AppState) => state.restaurants.detailRestaurant);

  // State for component
  const [loading, setLoading] = useState(false);
  const [showPayDialog, setShowPayDialog] = useState(false);
  const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
  const [showDeliverDialog, setShowDeliverDialog] = useState(false);
  const [showPaymentConfirmationDialog, setShowPaymentConfirmationDialog] = useState(false);
  const [showOberComingDialog, setShowOberComingDialog] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.PIN);
  const { connectionState, discardSocketSession } = useSocketServerConnection();
  const dispatch = useDispatch();
  const navigate = useHistory();

  const ordersFromHome = hasSession && session?.sessionType === SessionType.FROM_HOME;

  useEffect(() => {
    if(!restaurants)  return;

    if (!restaurant && hasSession) {
      const slug = restaurants.find((r) => r.id === session?.restaurantId)?.generateSlug();
      if (slug) dispatch(getRestaurant(slug));
    }

    window.scrollTo(0, 0);
  }, [hasSession, restaurants]);

  //TODO: CHECK CATEGORIES
  const _processItems = (items: HistoricOrder[]) => {
    let categories = [
      { type: "eten", title: "Eten" },
      {type: 'drinken', title: 'Drinken'},
      {type: undefined, title: 'Overige'},
    ];

    if (items.length === 0) return [];

    const mapped = categories
      .map((category) => {
        const orderItemsForCategory = items
          .filter((dish: any) => dish.type === category.type)
          .map((x: any) => ({ ...x, type: "order" }))
          .sort((a: any, b: any) => b.roundNumber - a.roundNumber);

        const merged: any[] = [];
        orderItemsForCategory.forEach((item: any) => {
          const index = merged.findIndex((i) => menuItemEqual(i, item));
          if (index === -1) {
            merged.push(item);
          } else {
            merged[index].amount = item.amount;
          }
        });
        return [{ type: "category", title: category.title }, ...merged];
      })
      .filter((groupedResult) => groupedResult.length > 1);

    return mapped.flat();
  };

  const menuItemEqual = (a: any, b: any) => {
    return a.menuItemId === b.menuItemId && a.comment === b.comment && _.isEqual(a.sideDishes, b.sideDishes);
  };

  const _getItems = () => {
    return _processItems(menuItemsQueue);
  };

  const _getAlreadyOrderedItems = () => {
    const mapOrderedItems = menuItemsOrdered.map((item: HistoricOrder) => {
      return { ...item, ordered: true };
    });
    return _processItems(mapOrderedItems);
  };

  const _getTotalPrice = (items: HistoricOrder[]) => {
    const tip = 0;
    return (
      (items.reduce((previous: number, current: any) => {
        const price = parseInt(getItemQueuePrice(current));
        const sideDishesPrice = getSideDishesPrice(current.sideDishes);
        return previous + (price + sideDishesPrice) * current.amount;
      }, 0) +
        (tip || 0)) /
      100
    ).toFixed(2);
  };

  const getSideDishesPrice = (sideDishes: any) => {
    let sideDishPrice = 0;
    if (sideDishes) {
      Object.keys(sideDishes).forEach((key) => {
        const dish = sideDishes[key];
        if (dish.options && dish.options.length > 0) {
          dish.options.forEach((option: any) => (sideDishPrice += parseInt(option.priceModifier)));
        }
      });
    }
    return sideDishPrice;
  };

  const getItemQueuePrice = (item: HistoricOrder) => {
    const originalPrice = item.price;

    const menu = _getMenu() as any;

    if (!menu) return originalPrice;

    if (item.status && item.status !== "queued") {
      return originalPrice;
    }

    const menuItem = menu.find((i: any) => i.id === item.id);

    if (!menuItem) {
      return originalPrice;
    }

    if (menuItem.actionActive) {
      return menuItem.actionPrice;
    }

    return menuItem.price;
  };

  const _getMenu = () => {
    if (restaurant?.id) {
      const restaurantMenu = menu[restaurant?.generateSlug() as keyof typeof menu];
      if (!restaurantMenu) {
        dispatch(getMenu(restaurant?.generateSlug()));
      }
      return restaurantMenu;
    }

    return null;
  };

  const addAmount = async (item: any, amount: number) => {
    if (!session) return;
    if (ordersFromHome) {
      const response = await api.updateOrder(session.token, item.orderId, amount);
      if(response.success){
        dispatch(changeMenuItemAmount(response.updatedOrderItems, amount));
      }else{
        dispatch(showNotification(response.message, NotificationType.ERROR));
      }
    } else {
      connectionState?.emit(ServerEvents.UpdateOrder, {orderId: item.orderId, amount}, (response: any) => {
        if (!response.success) {
          dispatch(showNotification(response.message, NotificationType.ERROR));
        }
      });
    }
  };

  const callOberClicked = async () => {
    if (!hasSession && !session) {
      return;
    }

    connectionState?.emit(ServerEvents.CallOber, undefined, (response: any) => {
      if (!response.success && response.message) {
        dispatch(showNotification(response.message, NotificationType.ERROR));
      } else if (!response.success) {
        dispatch(showNotification("Er ging iets mis, probeer het later opnieuw.", NotificationType.ERROR));
      }
    });
  };

  const leaveSessionClicked = () => {
    clearOrders();
    discardSocketSession();
    navigate.goBack();
  };

  const payClicked = () => {
    if (paymentStatus === 'payment-requested') {
      setShowOberComingDialog(true);
    } else if (session?.sessionType === SessionType.FROM_HOME) {
      setShowDeliverDialog(true);
      setShowSubmitConfirm(false);
    } else {
      setShowPayDialog(true);
      setShowSubmitConfirm(false);
    }
  };

  const onBack = () => {
    //TODO: CHECK
    if (hasSession && paymentStatus === 'closed') {
      clearOrders();
      removeSession();
    }
    return true;
  };

  const handleSubmitOrders = async () => {
    if (!session) return;
    setLoading(true);
    setShowSubmitConfirm(false);

    connectionState?.emit(ServerEvents.SubmitOrder, undefined, (response: any) => {
      setLoading(false);
      if (response.success) {
        dispatch(addSubmittedOrder());
        showNotification("Je bestelling is geplaatst en komt er z.s.m. aan!", NotificationType.SUCCESS);
      } else {
        showNotification(response.message, NotificationType.ERROR);
      }
    });
  };

  const paymentMethodClicked = async (method: PaymentMethod) => {
    setPaymentMethod(method);
    setShowPaymentConfirmationDialog(true);
    setShowPayDialog(false);
  };

  const pay = async (paymentMethod: any, tips: number) => {
    connectionState?.emit(ServerEvents.RequestPayment, { paymentMethod, tips, donations: 0 }, (response: any) => {

      setLoading(false);
      if (response.success) {
        if (response.paymentUrl) {
          window.open(response.paymentUrl, "_blank")?.focus();
        } else {
          updateSessionStatus("payment-requested");
          dispatch(showNotification("De ober komt eraan!", NotificationType.SUCCESS));
        }
        setShowPaymentConfirmationDialog(false);
      } else {
        dispatch(showNotification(response.message, NotificationType.ERROR));
      }
    });
  };

  const _renderContent = () => {
    const isOrdering = menuItemsQueue.length > 0;
    const canPay = menuItemsQueue.length === 0 && menuItemsOrdered.length > 0;
    const canCallOber = session?.sessionType === SessionType.RESTAURANT;
    const canLeaveSession = menuItemsOrdered.length <= 0 && session?.sessionType === SessionType.RESTAURANT;

    let items = isOrdering ? _getItems() : _getAlreadyOrderedItems();

    let itemQueue = menuItemsQueue;

    let cannotOrder = false;
    if (restaurant && restaurant.minAmount) {
      cannotOrder = Number(_getTotalPrice(menuItemsQueue)) * 100 < restaurant.minAmount;
    }
    if (session && session.sessionType !== "FROM_HOME") {
      cannotOrder = false;
    }

    if(cannotOrder && restaurant && restaurant.extraCostMinAmount > 0){
      cannotOrder = false;
      const extraCostExists = itemQueue.findIndex((item: any) => item.title === "Extra kosten") !== -1;
      if(!extraCostExists){
        const extraCost = parseInt(`${restaurant.extraCostMinAmount}`);
        const item = {
          amount: 1,
          title: 'Extra kosten',
          description: `Extra kosten omdat je onder het minimumbedrag van ${Formatters.formatCurrency(restaurant.minAmount)} zit`,
          hideAdd: true,
          originalPrice: extraCost,
          price: extraCost,
          actionActive: false,
          sideDishes: [],
        }
        itemQueue.push(item);
      }
    }

    return (
      <>
        {_renderItems(items)}
        {/* {session && session.tip > 0 && <OrderTip price={session.tip} />} */}
        <OrderTotal price={_getTotalPrice(isOrdering ? itemQueue.flat() : menuItemsOrdered)} />
        {isOrdering && (
          <>
            <Button
              text="Bestelling plaatsen"
              className="mt-20 ml-16 mr-16 mb-20"
              icon={<CheckIcon className="icon" fill="#ffffff" />}
              onClick={() => setShowSubmitConfirm(true)}
              disabled={cannotOrder}
            />
            {cannotOrder && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography>
                  Je zit niet aan het minium bedrag van {Formatters.formatCurrency(restaurant?.minAmount ?? 0)}
                </Typography>
              </div>
            )}
          </>
        )}
        {canPay && <Button text="Afrekenen" className="mt-20 ml-16 mr-16 mb-20" onClick={payClicked} />}
        {canCallOber && <AltAction text="Ober roepen" className="mb-30 mt-10" onClick={callOberClicked} />}

        {canLeaveSession && <AltAction text="Tafel verlaten" className="mb-30 mt-10" onClick={leaveSessionClicked} />}

        {isOrdering && menuItemsOrdered.length > 0 && _renderItems(_getAlreadyOrderedItems())}
        {isOrdering && menuItemsOrdered.length > 0 && <OrderTotal price={_getTotalPrice(menuItemsOrdered.flat())} />}
      </>
    );
  };

  const _renderItems = (itemsToRender: any[]) => {
    return itemsToRender.map((item, index) => {
      if (item.type === "category") {
        return <CategoryHeader title={item.title} key={index} />;
      }
      if (item.type === "order") {
        const details = item;

        return (
          <OrderItem
            title={details.title}
            description={details.description}
            extra={details.extra}
            addAmount={(amount: number) => addAmount(item, amount)}
            item={details}
            key={index}
            price={getItemQueuePrice(item)}
            ordered={item.ordered}
            sideDishes={details.sideDishes}
            comment={details.comment}
            allergies={details.allergies}
            amount={item.amount}
            status={item.status}
          />
        );
      }
      return <></>;
    });
  };

  return (
    <>
      {showPayDialog && (
        <PayDialog onCancel={() => setShowPayDialog(false)} onPaymentMethodSelected={paymentMethodClicked} />
      )}
      {showOberComingDialog && (
        <MessageDialog
          title={"De ober komt eraan"}
          description={"Je hebt al een ober gevraagd, even geduld a.u.b."}
          onComplete={() => {
            setShowOberComingDialog(false);
          }}
        />
      )}
      {showDeliverDialog && <DeliverDialog onCancel={() => setShowDeliverDialog(false)} />}
      {showPaymentConfirmationDialog && (
        <OrderConfirmationDialog
          paymentMethod={paymentMethod}
          onConfirm={(tipValue) => pay(paymentMethod, tipValue)}
          onCancel={() => {
            setShowPaymentConfirmationDialog(false);
            setShowPayDialog(true);
          }
        }
        />
      )}

      {showSubmitConfirm && (
        <YesNoDialog
          title="Weet je het zeker?"
          description="Is je bestelling zo compleet?"
          onComplete={ordersFromHome ? payClicked : handleSubmitOrders}
          onCancel={() => setShowSubmitConfirm(false)}
        />
      )}

      <Toolbar title="Bestellingen" back={true} onBack={onBack} />
      <Content toolbar={true}>
        {hasSession && (
          <TableHeader tableNumber={ordersFromHome ? `ORDER_${session.token}` : `Tafel ${session?.tableNumber}`} />
        )}
        <LoadingIndicator isLoading={loading} />
        {!loading && hasSession && _renderContent()}
      </Content>
    </>
  );
};

export default Orders;
