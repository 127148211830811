import React from 'react';
import Formatters from '../../../utils/formatters';
import OrderEntrySidedish from '../order-entry-sidedish/order-entry-sidedish';

import './order-entry.scss';

type Props = {
  amount: number;
  title: string;
  price: number;
  sideDishes: Array<any>;
};

const OrderEntry: React.FC<Props> = (props: Props) => {
  return (
    <div className="order-entry">
      <div className="title">
        <span>
          {props.amount}x {props.title}
        </span>
        <span>{Formatters.formatCurrency(props.price)}</span>
      </div>
      {
          props.sideDishes && Array.isArray(props.sideDishes) && (
              Object.keys(props.sideDishes)
              .map((sideDish: any) => props.sideDishes[sideDish])
              .map((dish: any) =>
                dish.options.map((option: any, index: number) => (
                  <OrderEntrySidedish key={index} option={option} />
                )),
              )
          )
      }
    </div>
  );
};

export default OrderEntry;
