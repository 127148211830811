import {useEffect, useState} from 'react';
import {Input} from '../ui-base';
import Formatters from '../../utils/formatters';

type Props = {
  initialValueInCents?: number;
  onValueChanged: (valueInCents: number) => void;
};

const formatInput = (value: string): string => {
  return Formatters.formatCurrency(value, false);
};

const CurrencyInput: React.FC<Props> = ({
  onValueChanged,
  initialValueInCents,
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>(
    initialValueInCents !== undefined
      ? formatInput(initialValueInCents.toString())
      : undefined,
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = formatInput(rawValue);

    setInputValue(formattedValue);

    // Notify parent components of the change in value
    onValueChanged(parseInt(formattedValue.replace(',', ''), 10));
  };

  return (
    <Input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      placeholder={'0,00'}
      prefix="€"
    />
  );
};

export default CurrencyInput;
