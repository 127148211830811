import SideDish from '../../../models/sidedish';

export default class GetMenuItemDetailsResponse {
  sideDishes: Array<SideDish> = [];

  constructor(json: any) {
    // Check if this property exists. Menu items without side dishes do not have this property in the API response
    if (json.menuItem?.sideDishes) {
      this.sideDishes = json.menuItem.sideDishes.map(
        (sideDish: any) =>
          new SideDish({key: sideDish.title, ...sideDish}),
      );
    }
  }
}
