import './payment-result.style.scss';

import queryString from 'query-string';
import {Component} from 'react';
import {connect} from 'react-redux';

import CashButton from '../../components/button-cash/button-cash';
import IdealButton from '../../components/button-ideal/button-ideal';
import PinButton from '../../components/button-pin/button-pin';
import Content from '../../components/content/content.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import {showNotification} from '../../redux/actions/notifications.actions';
import {cancelSession, removeSession} from '../../redux/session/session.thunks';
import api from '../../services/api';
import AltAction from '../../components/alt-action/alt.action.component';
import SessionType from '../../models/session-type';
import {RouteComponentProps} from 'react-router-dom';
import {NotificationType} from '../../models/notification';
import Session from '../../models/session';
import Restaurant from '../../models/restaurant';
import {AppState} from '../../redux/reducers/root.reducer';

type ReduxProps = {
  hasSession: boolean;
  session: Session | null;
  restaurant?: Restaurant;
};

type ReduxActions = {
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => Promise<void>;
  cancelSession: () => Promise<boolean>;
  removeSession: () => Promise<void>;
};

type Props = {} & ReduxProps & ReduxActions & RouteComponentProps;

class PaymentResult extends Component<Props> {
  componentDidMount = async () => {
    const {session, history} = this.props;
    const params = this._getData();
    if (params.success) {
      setTimeout(() => history.replace('/restaurants'), 5000);
    } else if (session) {
      history.replace('/orders');
    }
  };

  componentDidUpdate = async (prevProps: Props) => {
    const {hasSession, history} = this.props;
    if (!prevProps.hasSession && hasSession) {
      const {session} = this.props;
      const params = this._getData();
      if (params.success) {
        setTimeout(() => history.replace('/restaurants'), 5000);
      } else if (session) {
        history.replace('/orders');
      }
    }
  };

  _getData = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.result === '1') {
      let description = 'Bedankt voor uw bezoek!';
      if (params.orderType === 'delivery') {
        description =
          'Uw bestelling is ontvangen en betaald! Wij gaan voor je aan de slag en uw eten wordt straks bezorgd.';
      } else if (params.orderType === 'pickup') {
        description =
          'Uw bestelling is ontvangen en betaald! Wij gaan voor je aan de slag. Je kunt de bestelling straks komen afhalen.';
      }

      return {
        title: 'Wij hebben uw betaling succesvol ontvangen!',
        description: description,
        success: true,
      };
    } else if (params.result === '0' && params.status === 'pending') {
      let description =
        'Roep de ober om te controleren of betaling is geslaagd!';
      if (params.orderType === 'delivery') {
        description = 'Controleer met de bezorger of de betaling geslaagd is.';
      } else if (params.orderType === 'pickup') {
        description =
          'Controleer bij het restaurant of uw betaling geslaagd is.';
      }

      return {
        title: 'Betaling wordt verwerkt...',
        description: description,
        success: true,
      };
    } else if (
      params.result === '0' && ['failed', 'expired', 'canceled'].includes(params.status as string)
    ) {
      return {
        title: 'Betaling mislukt',
        description:
          'De betaling is mislukt of geannuleerd. Probeer het opnieuw:',
        retry: true,
        sessionId: params.sessionId,
        paymentIdentifier: params.paymentIdentifier,
        success: false,
      };
    } else {
      let description =
        'Er is iets mis gegaan met de betaling. Probeer het (later) opnieuw.';
      if (params.orderType === 'delivery') {
        description =
          'Er is iets mis gegaan met de betaling. Probeer het (later) opnieuw.';
      } else if (params.orderType === 'pickup') {
        description =
          'Er is iets mis gegaan met de betaling. Probeer het (later) opnieuw.';
      }

      return {
        title: 'Betaling mislukt',
        description: description,
        success: false,
      };
    }
  };

  cancelOrder = async () => {
    const success = await this.props.cancelSession();
    if (success) {
      this.props.showNotification(
        'Bestelling geannulleerd!',
        NotificationType.SUCCESS,
      );
      this.props.history.replace('/restaurants');
    }
  };

  pay = async (method: string) => {
    const {session} = this.props;

    if (session && session.sessionType === SessionType.FROM_HOME) {
      const result = await api.callOber(session.token, method);
      if (result.success) {
        this.props.showNotification(
          'Je bestelling is geplaatst, bedankt!',
          NotificationType.SUCCESS,
        );
      }
      this.props.removeSession();
    } else {
      await api.callOber(session?.token, method);

      this.props.showNotification(
        'De ober komt eraan!',
        NotificationType.SUCCESS,
      );
    }
    this.props.history.replace('/restaurants');
  };

  payIdeal = async () => {
    const {session} = this.props;

    const result = await api.paySession(session?.token);

    if (result.checkout_url) {
      window.location.href = result.checkout_url;
    }
  };

  _renderRedirect = () => {
    return (
      <a href="/restaurants">
        Klik hier om door te gaan (automatisch na 5 seconden)
      </a>
    );
  };

  _renderPaymentOptions = () => {
    const {restaurant} = this.props;
    if (!restaurant) {
      return null;
    }
    return (
      <>
        <PinButton
          disabled={!restaurant.pinEnabled}
          onClick={() => this.pay('pay-pin')}
        />
        <CashButton
          disabled={!restaurant.cashEnabled}
          onClick={() => this.pay('pay-cash')}
        />
        <IdealButton
          disabled={!(restaurant.mollieEnabled && restaurant.mollieValid)}
          iDealCost={restaurant.iDealCost}
          onClick={this.payIdeal}
        />

        <AltAction
          text="Bestelling annuleren"
          className="mt-40"
          icon="close"
          onClick={this.cancelOrder}
        />
      </>
    );
  };

  render() {
    const data = this._getData();

    return (
      <>
        <Toolbar showMenuIcon title="Betaling" />

        <Content toolbar={true}>
          <div className="payment_result_content full-width">
            <h1 className="payment_header_name">{data.title}</h1>
            <p>{data.description}</p>
            {!data.success && this._renderPaymentOptions()}
            {data.success && this._renderRedirect()}
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    hasSession: state.session.hasSession,
    session: state.session.session,
    restaurant: state.restaurants.detailRestaurant ?? undefined,
  };
};

export default connect(mapStateToProps, {
  showNotification,
  cancelSession,
  removeSession,
})(PaymentResult);
