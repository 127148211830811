import {Component} from 'react';
//@ts-ignore
import {QrReader} from 'react-qr-reader';

type Props = {
    onScanned?: (data: any) => void;
}

export default class Scanner extends Component<Props> {

    render() {
        return (
            <QrReader
                constraints={{ facingMode: 'user' }}
                scanDelay={300}
                onResult={(result, error) => {
                    if (!!result) {
                        this.handleScan(result.getText());
                      }

                      if (!!error) {
                        this.handleError(error)
                      }
                }}
            />
        );
    }

    handleScan = (data: string | null) => {
        const {onScanned} = this.props;
        if (data && onScanned) {
            onScanned(data);
        }
    };
    handleError = (err: any) => {
        console.error(err);
    };

}
