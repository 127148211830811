import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import Allergies from '../allergies/allergies.component';
import React, {useCallback} from 'react';
import './order.item.style.scss';
import OrderItemAmount from '../order-item-amount/order-item-amount';
import DOMPurify from 'dompurify';
import Formatters from '../../utils/formatters';

type Props = {
  title: string;
  description: string;
  extra: string;
  allergies: string[];
  amount: number;
  ordered: boolean;
  sideDishes: any[];
  comment: string;
  addAmount?: (amount: number) => void;
  removeItem?: () => void;
  status: string;
  item: any;
  price: string;
};

const OrderItem: React.FC<Props> = (props: Props) => {
  const {
    title,
    description,
    extra,
    allergies,
    amount,
    ordered,
    sideDishes,
    comment,
    removeItem,
    status,
  } = props;

  const changeAmount = useCallback(
    (amount: number) => {
      const {addAmount} = props;
      if (addAmount !== undefined) {
        addAmount(amount);
      }
    },
    [props],
  );

  const _renderOrderedItem = (
    title: string,
    comment: string,
    amount: number,
    status: string,
  ) => {
    let statusText = 'Status onbekend';
    if (status === 'queued') {
      statusText = 'Wacht op bevestiging';
    } else if (status === 'submitted') {
      statusText = 'In behandeling';
    } else if (status === 'progress') {
      statusText = 'Word nu bereid';
    } else if (status === 'ready') {
      statusText = 'Komt eraan!';
    } else if (status === 'served') {
      statusText = 'Afgeronde bestelling';
    }
    return (
      <div className="order_item ordered">
        <div className="order_item_row">
          <span className="order_item_ordered_amount">{`${amount}x `}</span>
          <span className="order_item_ordered_title">
            {title}
            <span className={`order_item_status ${status}`}>{statusText}</span>
            {comment && comment.length > 0 && (
              <h3 className="order_item_extra">{comment}</h3>
            )}
            {_renderOrderedSideDishesText()}
          </span>
          <div className="flex-1" />
          <span className="order_item_price">{`${_getPrice()}`}</span>
        </div>
      </div>
    );
  };

  const _renderOrderedSideDishesText = () => {
    const {sideDishes} = props;
    if (!sideDishes || !Array.isArray(sideDishes)) return <></>;
    return (
      <>
        <br />
        <span className="order_item_ordered_subtitle">
          {_getSideDishesText()}
        </span>
      </>
    );
  };

  const _getSideDishesText = () => {
    const {sideDishes} = props;

    let options: any[] = [];

    for(const sideDish of sideDishes){
      if (sideDish.options && sideDish.options.length > 0) {
        sideDish.options.forEach((option: any) => options.push(option.title));
      }
    }

    return options.join(', ');
  };

  const _getPrice = () => {
    const {price, amount} = props;
    const {sideDishes} = props;

    let sideDishPrice = 0;
    if (sideDishes && Array.isArray(sideDishes)) {
      for(const sideDish of sideDishes){
        if (sideDish.options && sideDish.options.length > 0) {
          sideDish.options.forEach(
            (option: any) => (sideDishPrice += parseInt(option.priceModifier)),
          );
        }
      }
    }

    return Formatters.formatCurrency(
      (parseInt(price) + sideDishPrice) * (amount ?? 1),
    );
  };

  if (ordered) {
    return _renderOrderedItem(title, comment, amount, status);
  }
  return (
    <div className="order_item">
      <div className="order_item_title_wrapper">
        <h1 className="order_item_title">{title}</h1>
        <DeleteIcon className="order_item_delete" onClick={removeItem} />
      </div>
      {description && (
        <h2
          className="order_item_description"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description, {
              ALLOWED_TAGS: [],
            }),
          }}></h2>
      )}
      {extra && <h3 className="order_item_extra">{extra}</h3>}
      {sideDishes && Array.isArray(sideDishes) && (
        <h3 className="order_item_extra">{_getSideDishesText()}</h3>
      )}
      {comment && comment.length > 0 && (
        <h3 className="order_item_extra">{comment}</h3>
      )}
      {allergies && <Allergies allergies={allergies} />}

      <div className="order_item_row">
        {!props.item.hideAdd && (
          <OrderItemAmount amount={amount} onChange={changeAmount} />
        )}

        <div className="flex-1" />

        <span className="order_item_price">{`${_getPrice()}`}</span>
      </div>
    </div>
  );
};

export default OrderItem;
