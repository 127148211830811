import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Socket, io } from "socket.io-client";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { NotificationType } from "../../models/notification";
import SessionType from "../../models/session-type";
import { showNotification } from "../../redux/actions/notifications.actions";
import { updateTableOrderHistory, updateTableOrders } from "../../redux/actions/orders.actions";
import { setTableSession, updateSessionStatus, removeSession } from "../../redux/session/session.thunks";
import { AppState } from "../../redux/reducers/root.reducer";

export enum ServerEvents {
	JoinTable = "join-table",
	AddOrder = "add-order",
	UpdateOrder = "update-order",
	SubmitOrder = "submit-order",
	RequestPayment = "request-payment",
	CallOber = "call-ober",
	Summerize = "summerize",
}

export enum ClientEvents {
	OrderUpdated = "order-updated",
	OrderSubmitted = "order-submitted",
	PaymentRequested = "payment-requested",
	PaymentConfirmed = "payment-confirmed",
	Notice = "notice",
}

export interface ScanData {
	restaurantId: number;
	token: string;
	tableNumber: number;
}

let connection: Promise<Socket> | null = null;

export const useSocketServerConnection = () => {
	const dispatch = useDispatch();
	const token = useSelector((state: AppState) => state.account.token);
	const session = useSelector((state: AppState) => state.session);
	const restaurant = useSelector((state: AppState) => state.restaurants.detailRestaurant);
	const [connectionState, setConnectionState] = useState<Socket | null>(null);
	const [scanData, setScanData] = useState<ScanData | null>(null);
	const [round, setRound] = useState(0);
	const history = useHistory();
	const data = useLocation().search;

	useEffect(() => {
		if (connection || !restaurant) return;
		const { restaurantId, token, tableNumber } = queryString.parse(data);
		// From QR code scan
		if (restaurantId && token && tableNumber) {
			joinSocketSession(Number(restaurantId as string), token as string, Number(tableNumber as string));
		}

		// Returning to session
		if (session.hasSession && session.session?.sessionType === SessionType.RESTAURANT) {
			const { restaurantId, token, tableNumber } = session.session;
			joinSocketSession(restaurantId, token, tableNumber as number);
		}
	}, [data, restaurant, session]);

	// Setup connection
	useEffect(() => {
		// Bail out if already set up
		if (connection) {
			(async () => {
				setConnectionState(await connection);
			})();
			return;
		}

		if (!scanData) return;

		connection = new Promise<Socket>((resolve) => {
			const newConnection = io(process.env.REACT_APP_API_URL as string, {
				transports: ["websocket"],
				auth: {
					token,
				},
			});

			newConnection.on("connect", () => {
				newConnection.emit(ServerEvents.JoinTable, scanData, (sessionData: any) => {
					console.info("Connected to SocketServer with socket id: ", newConnection.id);
					resolve(newConnection);

					if (sessionData?.currentRound) {
						if (!session.hasSession) {
							dispatch(setTableSession(scanData));
							dispatch(showNotification("Geslaagd! Je kunt nu bestellingen plaatsen.", NotificationType.SUCCESS));
						}
						const { currentRound, orderHistory } = sessionData;
						const mapOrderHistory = formatOrderHistory(orderHistory);

						dispatch(updateTableOrderHistory(mapOrderHistory));
						dispatch(updateTableOrders(currentRound.orderItems ?? []));
						dispatch(updateSessionStatus("open"));
						
						(async () => {
							setConnectionState(await connection);
						})();
					} else if (sessionData?.success === false) {
						dispatch(
							showNotification(
								sessionData?.message ?? "Er is iets fout gegaan, probeer het opnieuw",
								NotificationType.ERROR
							)
						);
						newConnection.disconnect();
					}
					// Redirect to restaurant page
					if (data) history.replace("/restaurant/" + restaurant?.generateSlug());
				});
			});

			newConnection?.on("disconnect", (reason) => {
				console.info("Disconnected from SocketServer", reason);
				setConnectionState(null);
			});
		});
	}, [scanData, token]);

	useEffect(() => {
		if (!connectionState) return;

		connectionState?.on(ClientEvents.Notice, ({ message, type }: any) => {
			console.info("Notice: ", message);
			dispatch(showNotification(message, type));
		});

		connectionState?.on(ClientEvents.OrderUpdated, ({ round, orderItems }: any) => {
			dispatch(updateTableOrders(orderItems));
			setRound(round);
		});

		connectionState?.on(ClientEvents.OrderSubmitted, ({ newRound, orderHistory }: any) => {
			const mapOrderHistory = orderHistory.reduce((acc: any, order: any) => {
				const orderItemsWithRoundnumber = order.orderItems.map((item: any) => ({
					...item,
					status: order.status,
					roundNumber: order.round,
				}));

				return [...acc, ...orderItemsWithRoundnumber];
			}, []);

			// if(newRound.round !== round){
				setRound(newRound.round);
				dispatch(updateTableOrderHistory(mapOrderHistory));
				dispatch(updateTableOrders(newRound.orderItems));
				dispatch(showNotification("Bestelling is geplaatst", NotificationType.SUCCESS));
			// }
		});

		connectionState?.on(ClientEvents.PaymentRequested, ({ orderHistory }: any) => {
			dispatch(updateSessionStatus("closed"));
			dispatch(showNotification("Er word een betaling gedaan", NotificationType.SUCCESS));
			});
			
			connectionState?.on(ClientEvents.PaymentConfirmed, () => {
			dispatch(showNotification("Betaling is ontvangen, de sessie word afgesloten", NotificationType.SUCCESS));
			discardSocketSession();
		});

		return () => {
			/*connectionState?.off("connect");
			connectionState?.off(ClientEvents.Notice);
			connectionState?.off(ClientEvents.OrderUpdated);
			connectionState?.off(ClientEvents.OrderSubmitted);
			connectionState?.off(ClientEvents.PaymentRequested);
			connectionState?.off("disconnect");*/
		};
	}, [connectionState, dispatch, session]);

	const formatOrderHistory = (orderHistory: any) => {
		return orderHistory.reduce((acc: any, order: any) => {
			const orderItemsWithRoundnumber = order.orderItems.map((item: any) => ({
				...item,
				status: order.status,
				roundNumber: order.round,
			}));

			return [...acc, ...orderItemsWithRoundnumber];
		}, []);
	};

	const joinSocketSession = (restaurantId: number, token: string, tableNumber: number) => {
		if (!restaurantId || !token || !tableNumber) {
			console.error("Cannot join socket session, missing required data");
			return;
		}

		setScanData({ restaurantId, token, tableNumber });
	};

	const discardSocketSession = () => {
		if (!connectionState) {
			console.error("Cannot discard socket session, missing connection");
			return;
		}

		dispatch(removeSession());
		setTimeout(() => history.replace('/restaurants'), 5000);

		connectionState.disconnect();
	};


	return {
		joinSocketSession,
		discardSocketSession,
		connectionState,
		round,
	};
};
