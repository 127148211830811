import React from 'react';
import {connect} from 'react-redux';
import AltAction from '../../../components/alt-action/alt.action.component';
import {Button, InputGroup, Label, ModalDialogDescription, ModalDialogTitle,} from '../../../components/ui-base';
import Restaurant from '../../../models/restaurant';
import RestaurantMenuItem from '../../../models/restaurantMenuItem';
import {AppState} from '../../../redux/reducers/root.reducer';
import CurrencyInput from '../../../components/currency-input/currency-input.component';
import _ from "lodash";

type SideDish = {
  id: number;
  adaptivityId: string,
  title: string;
  options: SideDishOption[];
};

type SideDishOption = {
  id: number;
  title: string;
  priceModifier: number;
};

type QueueItem = {
  menuItemId: number,
  title: string,
  adaptivityId: number,
  actionActive: boolean;
  originalPrice: number;
  price: number,
  sideDishes: SideDish[];
  image: string;
  orderId: number,
  amount: number,
  comment: string,
};

type ReduxProps = {
  restaurant: Restaurant | undefined;
  account: any;
  menuItemsQueue: Array<QueueItem>;
};

type Props = {
  menu: RestaurantMenuItem[];
  name: string;
  phone: string;
  streetname: string;
  city: string;
  zipcode: string;
  housenumber: string;
  email: string;
  donation: boolean;
  deliver: boolean;
  takeout: boolean;
  paymentMethod: 'pin' | 'cash' | 'ideal';
  tipValue: number;
  deliveryTime: moment.Moment;
  updateState: (state: {}) => void;
  submitOrders: () => Promise<void>;
} & ReduxProps;

const ConfirmationDialog: React.FC<Props> = (props) => {
  const {
    account,
    restaurant,
    menuItemsQueue,
    donation,
    deliver,
    takeout,
    tipValue,
    updateState,
  } = props;

  const _formatPrice = (priceText: number) => {
    return `€${(priceText / 100).toFixed(2)}`;
  };

  const getItemQueuePrice = (item: QueueItem) => {
    const originalPrice = item.originalPrice;

    const {menu} = props;
    if (!menu) return originalPrice;

    const menuItem = menu.find(
      (i: RestaurantMenuItem) => i.id === item.menuItemId,
    );

    if (!menuItem) return originalPrice;

    return item.price;
  };

  const getSideDishesPrice = (sideDishes: any) => {
    let sideDishPrice = 0;
    if (!sideDishes || !_.isArray(sideDishes)) return sideDishPrice;

    for (const sideDish of sideDishes) {
      if (sideDish.options && sideDish.options.length > 0) {
        sideDish.options.forEach(
            (option: any) => (sideDishPrice += option.priceModifier),
        );
      }
    }
    return sideDishPrice;
  };

  const _getTotalPrice = (order: QueueItem[]) => {
    const {donation, deliver, paymentMethod, tipValue, restaurant} = props;

    let deliveryCosts = 0;
    if (deliver) {
      deliveryCosts = restaurant!.deliveryCost ? parseInt(restaurant!.deliveryCost) : 0;
    }
    let transactionCosts = 0;
    if (paymentMethod === 'ideal') {
      transactionCosts = restaurant!.iDealCost ? parseInt(restaurant!.iDealCost) : 0;
    }
    console.log('order', order);

    const orderItemsPrice = order
        .map(
            (dish: any) => {
              return dish.amount *
                  (getItemQueuePrice(dish) +
                      getSideDishesPrice(dish.sideDishes));
            },
        )
        .reduce((a: any, b: any) => a + b, 0);
    console.log(orderItemsPrice);

    return orderItemsPrice +
        (donation ? 100 : 0) +
        (tipValue ? tipValue : 0) +
        deliveryCosts +
        transactionCosts;
  };

  return (
    <>
      <ModalDialogTitle className="mt-20">Bevestig je order</ModalDialogTitle>
      <ModalDialogDescription className="pay_dialog_description">
        Controleer je gegevens en bestelling voor je hem doorstuurt.
      </ModalDialogDescription>

      <ModalDialogTitle className="pay_dialog_secondary_title mt-20">
        Gegevens
      </ModalDialogTitle>
      <div className="pay_dialog_userdata">{props.name}</div>
      <div className="pay_dialog_userdata">{props.phone}</div>
      <div className="pay_dialog_userdata">{props.email}</div>
      {deliver && (
        <>
          <div className="pay_dialog_userdata">
            {props.streetname} {props.housenumber}
          </div>
          <div className="pay_dialog_userdata">
            {props.zipcode} {props.city}
          </div>
        </>
      )}
      <div className="pay_dialog_userdata mt-10">
        {props.deliveryTime.format('DD/MM/YYYY')}
      </div>
      <div className="pay_dialog_userdata">
        {props.deliveryTime.format('HH:mm')}
      </div>
      {donation && (
        <div className="pay_dialog_userdata mt-10">
          Ik doneer 1 euro voor stichting Met je Hart
        </div>
      )}

      <div className="pay_dialog_dishes">
        <h1 className="pay_dialog_secondary_title mt-20">Bestelling</h1>
        {menuItemsQueue.map((menuItem) => {
          return (
              <>
                <div className="pay_dialog_menuitem">
                  <div className="pay_dialog_menuitem_title">
                  <span>
                    {menuItem.amount}x {menuItem.title}
                  </span>
                    <span>
                    {_formatPrice(
                        getItemQueuePrice(menuItem) * menuItem.amount,
                    )}
                  </span>
                  </div>
                  {menuItem.sideDishes.map((sideDish) => {
                    <>
                      {sideDish.options.map((option: any) => (
                          <div className="pay_dialog_menuitem_sidedish">
                            <span>{option.title}</span>
                            <span>{_formatPrice(option.priceModifier)}</span>
                          </div>
                      ))}
                    </>
                  })}
                </div>
              </>
          );
        })}
        {donation && (
          <>
            <div className="pay_dialog_menuitem">
              <div className="pay_dialog_menuitem_title">
                <span>Donatie</span>
                <span>{_formatPrice(100)}</span>
              </div>
            </div>
          </>
        )}
        {tipValue > 0 && (
          <>
            <div className="pay_dialog_menuitem">
              <div className="pay_dialog_menuitem_title">
                <span>Fooi</span>
                <span>{_formatPrice(tipValue)}</span>
              </div>
            </div>
          </>
        )}
        {deliver && (
          <div className="pay_dialog_menuitem">
            <div className="pay_dialog_menuitem_title">
              <span>Bezorgkosten</span>
              <span>{_formatPrice(parseInt(restaurant!.deliveryCost))}</span>
            </div>
          </div>
        )}
        {props.paymentMethod === 'ideal' && (
          <div className="pay_dialog_menuitem">
            <div className="pay_dialog_menuitem_title">
              <span>iDeal transactiekosten</span>
              <span>{_formatPrice(parseInt(restaurant!.iDealCost))}</span>
            </div>
          </div>
        )}
        <div className="pay_dialog_total">
          <span>Totaal</span>
          <span className="pay_dialog_total_price">
            {_formatPrice(_getTotalPrice(menuItemsQueue))}
          </span>
        </div>
      </div>

      {restaurant?.enableTipping && (
        <InputGroup>
          <Label className="full-width">
            Wil je een fooi geven, vul dan hier een bedrag in.
          </Label>

          <CurrencyInput
            onValueChanged={(valueInCents) => {
              updateState({tipValue: valueInCents});
            }}
          />
        </InputGroup>
      )}

      <Button
        text="Verstuur bestelling"
        className="mt-40"
        onClick={async () => await props.submitOrders()}
      />
      {!account.loggedIn && (
        <ModalDialogDescription className="pay_dialog_description">
          Wil je een overzicht van je bestellingen bewaren?{' '}
          <a href="/login">Maak dan een account aan!</a>
        </ModalDialogDescription>
      )}
      <AltAction
        text="Terug"
        className="mt-20"
        icon="close"
        onClick={() =>
          updateState({
            showConfirmOrder: false,
            showTakeoutContent: takeout,
            showDeliverContent: deliver,
          })
        }
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    menuItemsQueue: state.orders.menuItemsQueue,
    account: state.account,
    restaurant: state.restaurants.detailRestaurant ?? undefined,
  };
};

export default connect(mapStateToProps, {})(ConfirmationDialog);
