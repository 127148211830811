export default class RestaurantMenuItem {
  id: number;
  sku: string;
  restaurantId: number;
  adaptivityId: string;
  image: string;
  imageSquare: string;
  title: string;
  subTitle: string;
  description: string;
  extraDescription: string;
  price: number;
  active: boolean;
  actionActive: boolean;
  actionPrice: number;
  type: string;
  /**
   * Comma separated
   */
  mealTypes: string[];
  categoryId: string;
  highlight: string;
  ingredients: string;
  /**
   * Comma separated
   */
  allergies: string;
  orderNum: string;
  category: string;

  constructor(json: any) {
    this.id = json.id;
    this.sku = json.sku;
    this.restaurantId = json.restaurantId;
    this.adaptivityId = json.adaptivityId;
    this.image = json.image;
    this.imageSquare = json.imageSquare;
    this.title = json.title;
    this.subTitle = json.subTitle;
    this.description = json.description;
    this.extraDescription = json.extraDescription;
    this.price = json.price;
    this.active = json.active;
    this.actionActive = json.actionActive;
    this.actionPrice = json.actionPrice;
    this.type = json.type;
    this.mealTypes = json.mealTypes;
    this.categoryId = json.categoryId;
    this.highlight = json.highlighted;
    this.ingredients = json.ingredients;
    this.allergies = json.allergies;
    this.orderNum = json.orderNum;
    this.category = json.category;
  }
}
