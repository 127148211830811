import axios from 'axios';
import {FaqItemProps} from '../../screens/landing-page/faq/components/faq-item';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export default class FaqsAPI {
  getFaq = async (): Promise<FaqItemProps[]> => {
    const url = `${API_URL}/content/faq`;
    const response = await axios.get(url);

    if (response.status !== 200) {
      return [];
    }

    return response.data.faq.map((item: any) => ({
      answer: item.answer,
      question: item.question,
    }));
  };
}
