import React, {useEffect, useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import 'react-datepicker/dist/react-datepicker.css';
import './step2.scss';
import {DateTime} from 'luxon';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../../../redux/reducers/root.reducer';

type Props = {
  reservationDate: Date;
  setReservationDate: (date: Date) => void;
};

const Step2: React.FC<Props> = ({reservationDate, setReservationDate}) => {
  const [dateLocalesRegistered, setDateLocalesRegistered] = useState(false);
  const restaurant = useSelector(
    (appState: AppState) => appState.restaurants.detailRestaurant,
  );

  const isRestaurantClosed = (date: Date) => {
    date.setDate(date.getDate() + 1);

    const luxonDate = DateTime.fromJSDate(date);

    if (
      restaurant &&
      restaurant.exceptionClosedOn &&
      restaurant.exceptionClosedOn.includes(luxonDate.toFormat('yyyy-LL-dd'))
    ) {
      return false;
    }

    if (
      restaurant &&
      restaurant.exceptionOpenOn &&
      restaurant.exceptionOpenOn.includes(luxonDate.toFormat('yyyy-LL-dd'))
    ) {
      return true;
    }

    const day = restaurant?.openingTimes.find(
      (dayTime) => dayTime.day === date.getDay(),
    );

    return day !== undefined;
  };

  useEffect(() => {
    registerLocale('nl', nl);
    setDateLocalesRegistered(true);
  }, []);

  return (
    <div className="step2">
      {dateLocalesRegistered && (
        <DatePicker
          minDate={new Date()}
          maxDate={DateTime.now().setZone('Europe/Amsterdam').plus({months: 6}).toJSDate()}
          selected={reservationDate}
          filterDate={isRestaurantClosed}
          onChange={(date: Date) => setReservationDate(date)}
          inline
          locale="nl"
        />
      )}
    </div>
  );
};

export default Step2;
