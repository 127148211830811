import TableDialog from '../../sections/table-dialog/table.dialog.component';
import {showNotification} from '../../redux/actions/notifications.actions';
import Scanner from '../../components/scanner/scanner.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import Content from '../../components/content/content.component';
import {setOrderSession} from '../../redux/session/session.thunks';
import {Button, Label} from '../../components/ui-base';
import {sendToDevice} from '../../utils/native_utils';
import queryString from 'query-string';
import {Component} from 'react';
import {connect} from 'react-redux';
import Bg from '../../utils/bg';
import {AppState} from '../../redux/reducers/root.reducer';
import {NotificationType} from '../../models/notification';
import Session from '../../models/session';
import {RouteComponentProps} from 'react-router-dom';
import Restaurant from '../../models/restaurant';

declare global {
  interface Window {
    qrScanned: (value: string) => void;
  }
}

type ReduxProps = {
  loggedIn: boolean;
  token: string;
  restaurants: Array<Restaurant>;
};

type ReduxActions = {
  joinSession: (
    restaurantId: number,
    token: string,
    tableNumber: string,
    userToken: string,
    ggdOptInEnabled: boolean,
    isSameHousehold: boolean,
    hasNoSymptoms: boolean,
    name: string,
    phoneNumber: string,
  ) => Promise<Session | null>;
  showNotification: (text: string, type: NotificationType) => void;
};

type Props = {} & ReduxActions & ReduxProps & RouteComponentProps;

class QRScanner extends Component<Props> {
  state = {
    value: '',
    targetRestaurantId: 0,
    targetRestaurantToken: '',
    targetTableNumber: 0,
    showTableDialog: false,
    showScanner: false,
    isNativeApp: false,
  };

  componentDidMount = () => {
    if (window) {
      window.qrScanned = this.onScanned;
    }

    if (localStorage.getItem('is_native_app')) {
      this.openNativeScanner();
      this.setState({isNativeApp: true});
    } else {
      this.setState({showScanner: true});
    }
  };

  openNativeScanner = () => {
    sendToDevice({method: 'scan_qr'});
  };

  onDialogCancel = () => {
    this.setState({value: '', showTableDialog: false});
  };

  onDialogComplete = async (
    tableNumber: string,
    personAmount: number,
    hasNoSymptoms: boolean,
    isSameHousehold: boolean,
    ggdOptInChecked: boolean,
    extraData: any,
  ) => {
    const {joinSession, restaurants} = this.props;
    const {targetRestaurantId, targetRestaurantToken} = this.state;

    const {token, loggedIn} = this.props;

    const userToken = loggedIn ? token : '';

    const session = await joinSession(
      targetRestaurantId,
      targetRestaurantToken,
      tableNumber,
      userToken,
      ggdOptInChecked,
      isSameHousehold,
      hasNoSymptoms,
      extraData?.name,
      extraData?.phoneNumber,
    );
    if (session) {
      this.props.showNotification(
        'Geslaagd! Je kunt nu bestellingen plaatsen.',
        NotificationType.SUCCESS,
      );

      const targetRestaurant = restaurants.find(
        (x) => x.id === targetRestaurantId,
      )!!;
      this.props.history.push(`/restaurant/${targetRestaurant.generateSlug()}`);
    }
  };

  onScanned = (value: string) => {
    if (this.state.value === value || this.state.showTableDialog) {
      return;
    }

    this.setState({value: value});
    const data = queryString.parseUrl(value).query;

    if (data.restaurantId && data.token && data.tableNumber) {
      this.setState({
        showTableDialog: true,
        targetRestaurantId: parseInt(data.restaurantId as string),
        targetRestaurantToken: data.token,
        targetTableNumber:  parseInt(data.tableNumber as string),
      });
    }
  };

  _renderNativeContent = () => {
    return (
      <Button
        className="mt-90 ml-40 mr-40"
        text="Klik hier om opnieuw te scannen"
        onClick={this.openNativeScanner}
      />
    );
  };

  render() {
    const {showTableDialog, targetTableNumber, showScanner, isNativeApp} =
      this.state;

    return (
      <>
        {showTableDialog && (
          <TableDialog
            onCancel={this.onDialogCancel}
            onComplete={this.onDialogComplete}
            tableNumber={targetTableNumber}
          />
        )}

        <Bg bg="bg-primary" />

        <Toolbar back={true} title="Scan de QR-code" />
        <Content toolbar={true}>
          {showScanner && <Scanner onScanned={this.onScanned} />}

          {showScanner && (
            <Label className="text-color-white text-center mt-40 ml-40 mr-40">
              De QR-code wordt automatisch gedetecteerd zodra je deze binnen de
              hulplijnen hebt geplaatst.
            </Label>
          )}

          {isNativeApp && this._renderNativeContent()}
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): ReduxProps => {
  return {
    token: state.account.token,
    loggedIn: state.account.loggedIn,
    restaurants: state.restaurants.restaurants,
  };
};

export default connect(mapStateToProps, {showNotification, setOrderSession})(
  QRScanner,
);
