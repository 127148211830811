import moment, {Moment} from 'moment';
import Timeslot from '../../models/timeslot';
import {DateTime} from 'luxon';

export type OpeningTime = {
  from: DateTime;
  to: DateTime;
};

export default class TimeslotGenerator {
  /**
   *
   * @param openingTime From when to when a restaurant is open
   * @param interval The gap to leave between timeslots, should be greater than 0 and an error will be thrown when passing 0
   * @param now Moment to use as current time, can be used to build timeslots starting from a specific moment in time
   * @param allowWithinThisHour When false, will make sure generated timeslots start at the next hour.
   */
  static generateTimeslots = (
    openingTime: OpeningTime,
    interval: number,
    now: Moment = moment(),
    allowWithinThisHour: boolean = false,
  ): Array<Timeslot> => {
    const inputClone = moment(now);
    if (interval <= 0) {
      throw new Error(
        'Interval should be greater than 0, ' + interval + ' was passed.',
      );
    }

    const output: Array<Timeslot> = [];
    if (!allowWithinThisHour) {
      inputClone.add(1, 'hour');
      inputClone.add(1, 'minute');
    }

    const minute = inputClone.minute();
    if (interval === 15 && minute >= 45) {
      inputClone.hour(inputClone.hour() + 1);
    } else if (interval === 30 && minute >= 30) {
      inputClone.hour(inputClone.hour() + 1);
    } else if (interval >= 45) {
      inputClone.hour(inputClone.hour() + 1);
    }

    inputClone.minute(
      (Math.ceil(inputClone.minute() / interval) * interval) % 60,
    );
    inputClone.second(0);

    const firstAvailable = inputClone.isAfter(moment(openingTime.from))
      ? inputClone
      : moment(openingTime.from);
    firstAvailable.minute(
      (Math.ceil(firstAvailable.minute() / interval) * interval) % 60,
    );

    let date = DateTime.fromISO(firstAvailable.toISOString());
    //TEMP: Remove when everything is luxon
    const lastAvailable = openingTime.to.set({
      day: date.day,
      month: date.month,
      year: date.year,
    }).startOf('hour');

    while (date.toFormat('HH:mm') <= lastAvailable.toFormat('HH:mm')) {
      output.push(new Timeslot(moment(date.toJSDate())));
      date = date.plus({minutes: interval});
    }

    return output;
  };
}
