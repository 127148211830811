export class SideDishOption {
  id: number;
  title: string;
  price: number;

  constructor(json: any) {
    this.id = json.id;
    this.title = json.title;
    this.price = json.priceModifier;
  }
}

export default class SideDish {
  key: string;
  title: string;
  multiSelectable: boolean;
  items: Array<SideDishOption>;

  constructor(json: any) {
    this.key = json.key;
    this.title = json.title;
    this.multiSelectable = json.multiSelectable;
    this.items = json.options.map((x: any) => new SideDishOption(x));
  }
}
